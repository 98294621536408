.root > .aaaem-container,
footer .aaaem-container,
header .aaaem-container,
.cmp-experiencefragment--isi > .aaaem-container {
  &.cmp-container {
    max-width: none;
  }
}

.aaaem-container {
  // increasing specificity to override styles from AAAEM common
  &.cmp-container {
    max-width: var(--emu-component-container-max-width);
  }
}
