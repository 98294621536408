.journey-redirector {
  // overrides for the teaser component
  &.two-col-teaser
    .teaser
    .emu-teaser.emu-teaser-v3.emu-teaser-v3.emu-teaser-v3.emu-teaser-v3
    .teaser-container
    .content-container
    .emu-teaser__disclaimer {
    margin-top: 31px;
  }

  &__content {
    @include mq('large') {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    > .title {
      @include mq('large') {
        flex: 1;
      }
    }
  }

  &__title {
    padding-top: 39px;
    padding-bottom: 57px;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-top: 60px;
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-brand-medium);
      padding-right: var(--emu-common-spacing-brand-medium);
    }

    .cmp-title__text {
      @include mq('large') {
        font-size: 56px; // no variable available for this font size
        line-height: var(--emu-common-line-heights-custom-xs);
        letter-spacing: -1px;
      }
    }
  }

  &__links {
    ul {
      li {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 15px;
          padding-bottom: 7.5px; // to match figma
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-black);

          @include mq('large') {
            padding-top: var(--emu-common-spacing-brand-xs);
            padding-bottom: 14px;
            padding-left: var(--emu-common-spacing-brand-medium);
            padding-right: var(--emu-common-spacing-brand-medium);
          }

          &::after {
            content: url('../../assets/images/redirection-icon.jpg');
            display: block;
          }
        }
      }

      li + li {
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }
}
