.accordion-card {
  &__inner {
    display: flex;
    flex-direction: column-reverse;

    @include mq('large') {
      flex-direction: row;
    }

    > div {
      flex: 1;
    }
  }

  &__img-section {
    margin-top: 3px; // to match the design

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }
  }

  &__content-section {
    padding-top: 39px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mq('large') {
      padding-top: 70px;
    }

    > .container:first-child {
      flex: 1;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      margin-bottom: -3px;

      @include mq('large') {
        padding-left: var(--emu-common-spacing-brand-medium);
        padding-right: var(--emu-common-spacing-brand-medium);
        margin-bottom: 0px;
      }
    }

    .aaaem-accordion {
      .aaaem-accordion {
        &__panel {
          transform: none;
        }

        &__title {
          letter-spacing: 0;

          @include mq('large') {
            letter-spacing: -0.24px;
          }
        }

        &__header {
          padding-top: 18px;
          padding-right: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-small);
          padding-left: var(--emu-common-spacing-medium);
          min-height: var(--emu-common-sizing-large); // As per design

          @include mq('large') {
            min-height: 80px; // As per design
            padding-right: var(--emu-common-spacing-brand-medium);
            padding-bottom: 6px;
            padding-left: var(--emu-common-spacing-brand-medium);
          }

          &.-ba-is-active {
            @include mq('large', 'max-width') {
              padding-top: 29px; // intentional to match the design. The padding being added to expanded state is looking weird, but this matches the design
            }

            .aaaem-accordion__icon {
              &:before,
              &:after {
                background-color: var(--emu-common-colors-white);
              }
            }
          }

          .aaaem-accordion__icon {
            &:before,
            &:after {
              background-color: var(--emu-common-colors-primary-500);
              height: var(--emu-common-sizing-small);
            }
          }

          .plus-minus-icon .aaaem-accordion__icon {
            width: 34px;
            height: 34px;
            top: 0;

            @include mq('large') {
              top: 6px;
            }
          }
        }
      }

      .js-badger-accordion-panel-inner {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          padding-bottom: 23px;
        }
      }
    }
  }

  &__content-item {
    display: flex;
    flex-direction: column;

    @include mq('large') {
      flex-direction: row;
    }

    &-list {
      &--right {
        @include mq('large') {
          margin-left: var(--emu-common-spacing-medium);
        }
      }
    }

    > .container {
      flex: 1;

      &:first-child {
        @include mq('large') {
          margin-right: 25px;
        }
      }
    }

    &-title .cmp-title__text {
      margin-bottom: var(--emu-common-spacing-brand-xs);
    }

    &-subtitle {
      margin-bottom: 11px;

      @include mq('large', 'max-width') {
        margin-top: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        margin-bottom: 12px;
      }

      p {
        @include mq('large', 'max-width') {
          font-size: var(--emu-common-font-sizes-body-medium);
        }
      }
    }

    &-text {
      margin-bottom: 14px;

      // increasing specificity
      &.cmp-text {
        h3,
        h4,
        h5 {
          font-weight: var(--emu-common-font-weight-bold);
        }
      }
    }

    &-disc {
      margin-top: var(--emu-common-spacing-xs);

      @include mq('large') {
        margin-top: 6px;
      }
    }
  }

  &__img-section {
    img {
      width: 100%;

      @include mq('large', 'max-width') {
        max-height: 353px;
        object-fit: cover;
      }
    }
  }

  &__title.cmp-title {
    max-width: 480px; // As per figma
    margin-bottom: 22px;
    letter-spacing: -1px;

    @include mq('large', 'max-width') {
      letter-spacing: 0.1px;
      margin-bottom: 17px;
    }

    sup {
      font-size: 54%;
      left: 1px;
      top: -1px;

      @include mq('large') {
        font-size: 60%;
        left: 4px;
        top: 0;
      }
    }
  }

  &__subtitle .cmp-title__text {
    letter-spacing: -0.1px;

    @include mq('large', 'max-width') {
      margin-bottom: 48px;
    }

    sup {
      font-size: 68%;
      top: 2px;
    }
  }
}
