.header {
  &__top-bar {
    padding-left: 14px;
    padding-right: 14px;

    @include mq('389px', 'max-width') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }
  }
}
