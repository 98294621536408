@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-regular-italic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-semi-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-semi-bold-italic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-black.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 800;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-black-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 200;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-extra-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 200;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-extra-light-italic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-light.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-light-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-medium-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 900;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-super.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 900;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-super-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/graphik/graphik-thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-display: swap;
  font-weight: 100;
  font-style: italic;
  src: url('./assets/fonts/graphik/graphik-thin-italic.woff2') format('woff2');
}
