// Double Points 2025 modal styles
[data-component='modal'][data-id='double-points-25-modal'].double-points-25-modal {
  .modal-wrapper {
    .modal-content {
      font-family: var(--emu-common-font-families-graphik);
      padding: var(--emu-common-spacing-none);
      width: 320px; // As per figma design.
      position: relative;

      @include mq('large') {
        width: 760px; //as per figma design
      }

      .close {
        opacity: 1;
        padding: var(--emu-common-spacing-none);

        span {
          font-weight: var(--emu-common-font-weight-black);
          color: var(--emu-common-colors-black);
          top: 0;
          right: 0;
          font-size: 30px;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .double-points-25 {
    &__title-container {
      padding-top: 18px;
      padding-left: 17px;
      padding-right: 18px;
      max-width: 239px; //to match with design

      @include mq('large') {
        padding-top: 19px;
        padding-right: 27px;
        padding-left: 27px;
        max-width: none;
      }
    }

    &__pretitle {
      margin-bottom: var(--emu-common-spacing-xxs);

      @include mq('large') {
        margin-bottom: 9px;
      }

      p {
        font-size: 15px;
        line-height: 1;
        margin-bottom: var(--emu-common-spacing-none);
        letter-spacing: -0.15px; // as per figma
      }
    }

    &__title {
      .cmp-title__text {
        font-size: 36px;
        margin-left: -2px; // needed to match the figma design
        line-height: 1;
        letter-spacing: -1.8px;
        font-family: var(--emu-common-font-families-graphik);
        font-weight: 500;
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('large') {
          margin-left: -3px;
          font-size: 47.5px;
          letter-spacing: -2.375px;
        }

        sup {
          top: 8px;
          left: 3px;

          @include mq('large') {
            left: 1px;
            font-size: 70%;
          }
        }
      }
    }

    &__band {
      background-color: var(--emu-common-colors-tertiary-500);
      padding-top: 6px;
      padding-right: 13px;
      padding-bottom: 5px;
      padding-left: 13px;

      @include mq('large') {
        padding-top: 5px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
      }

      > p,
      li {
        font-size: 12.83px; // as per figma
        line-height: 21px; // to match the figma design
        letter-spacing: -0.385px; // as per figma
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: 15.833px; // as per figma
          letter-spacing: -0.475px; // as per figma
        }
      }
    }

    &__img {
      @include mq('large') {
        height: 100%;
      }

      &-inner {
        &.desktop-view {
          display: none;

          @include mq('large') {
            display: flex;
          }
        }

        &.tablet-mobile-view {
          display: flex;

          @include mq('large') {
            display: none;
          }
        }

        > .image {
          flex: 1;
          width: 50%;
        }
      }

      img {
        @include mq('large') {
          height: 100%;
          object-fit: cover;
        }
      }

      &-disc {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 15px;
        margin-top: 10px;

        @include mq('large') {
          padding-left: 23px;
          padding-right: 23px;
          margin-top: 14px;
          margin-bottom: 12px;
        }

        p,
        li {
          letter-spacing: -0.36px; // as per figma
          text-indent: -6px;
          margin-bottom: var(--emu-common-spacing-none);
          font-size: var(--emu-common-font-sizes-captions-large);
          line-height: 17px;

          @include mq('large') {
            letter-spacing: -0.415px; // as per figma
            font-size: 13.83px; // as per figma
            line-height: 20.583px; // as per figma
          }

          sup {
            font-size: var(--emu-common-font-sizes-body-regular);
            top: 9px;

            @include mq('large') {
              top: 6px;
            }
          }
        }
      }
    }

    &__action-container {
      margin-bottom: 12px;

      @include mq('large') {
        display: flex;
        margin-bottom: var(--emu-common-spacing-xxs);
      }

      > .container {
        // CTA Container
        &:last-child {
          @include mq('large') {
            flex: 1;
          }
        }
      }
    }

    &__logo {
      &--botox {
        margin-top: -2px; // to match the design
      }

      &-container {
        padding-left: 14px;
        padding-right: 14px;
        margin-bottom: 11px;

        @include mq('large') {
          padding-left: 21px;
          padding-right: 21px;
        }

        &.desktop-view {
          display: none;

          @include mq('large') {
            display: flex;
          }
        }

        &.tablet-mobile-view {
          display: flex;

          @include mq('large') {
            display: none;
          }
        }

        > .image {
          display: flex;

          &:not(:last-child) {
            &::after {
              content: '';
              display: block;
              width: var(--emu-common-sizing-xxs);
              height: 100%;
              align-self: stretch;
              background-color: var(--emu-common-colors-black);
              margin-left: 15px;
              margin-right: 19px;

              @include mq('large') {
                margin-left: 27px;
                margin-right: 31px;
                margin-top: -3px;
              }
            }
          }
        }
      }
    }

    &__cta {
      padding-top: 7px;
      padding-right: 28.5px; // to match the design
      padding-bottom: 7px;
      padding-left: 28.5px; // to match the design
      font-size: 8.732px; // as per figma
      line-height: 140%; // as per figma
      border-radius: 29.043px;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-small);
        padding-top: 12px;
        padding-right: 52px;
        padding-bottom: 12px;
        padding-left: 52px;
        font-size: 13.199px;
        border-radius: 43.898px;
      }

      span {
        font-family: var(--emu-common-font-families-graphik);
        font-weight: var(--emu-common-font-weight-medium);

        .cmp-button__text {
          letter-spacing: 0.1px;
        }
      }

      &-container {
        display: flex;
        justify-content: center;

        @include mq('large') {
          padding-right: 51px;
          justify-content: flex-end;
        }
      }
    }

    &__disc {
      padding-left: 6px;
      padding-right: 6px;
      padding-bottom: 6px;

      @include mq('large') {
        padding-left: 9px;
        padding-right: 9px;
      }

      p,
      li {
        font-size: 7px;
        line-height: 1.25; // to match figma
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: 9px;
          line-height: 12.5px; // to match the design
        }
      }

      a {
        display: inline-block;
        color: inherit;
        font-size: 8px;

        @include mq('large') {
          font-size: 9px;
        }
      }

      b {
        letter-spacing: -0.02px; // to match figma

        @include mq('large') {
          letter-spacing: -0.05px; // to match figma
        }
      }
    }
  }
}
