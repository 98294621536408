.aaaem-tabs {
  &__tablist {
    overflow: auto;
    padding-top: 31px;
    padding-right: 17px;
    padding-left: 17px;
    border-bottom: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-black);

    @include mq('large') {
      padding-top: 50px;
      padding-left: var(--emu-common-spacing-brand-medium);
    }

    li + li {
      margin-top: var(--emu-common-spacing-none);
    }

    a {
      white-space: nowrap;
    }
  }

  &__tab {
    font: var(--emu-component-ingredients-title-h5-typography-narrow);
    letter-spacing: -0.3px;

    @include mq('large') {
      font: var(--emu-component-ingredients-title-h5-typography-wide);
    }

    a:not([aria-selected='true']) {
      opacity: 0.5;
    }
  }
}
