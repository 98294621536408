
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-common-colors-white: #FFFFFF !default;
$emu-common-colors-black: #000000 !default;
$emu-common-colors-transparent: rgba(0,0,0,0) !default;
$emu-common-colors-dark-grey-100: #E8E8E8 !default;
$emu-common-colors-light-grey-100: #FAFAFA !default;
$emu-common-colors-light-grey-200: #B2B2B2 !default;
$emu-common-colors-primary-100: #F0F0FF !default;
$emu-common-colors-primary-200: #E1E1FE !default;
$emu-common-colors-primary-300: #CECEFF !default;
$emu-common-colors-primary-400: #B5B5FD !default;
$emu-common-colors-primary-500: #9898FC !default;
$emu-common-colors-primary-600: #989BF9 !default;
$emu-common-colors-primary-700: #ccccfe !default;
$emu-common-colors-primary-800: #A3A4F7 !default;
$emu-common-colors-secondary-100: #FBFAF8 !default;
$emu-common-colors-secondary-200: #F7F5F1 !default;
$emu-common-colors-secondary-300: #F2F0EC !default;
$emu-common-colors-secondary-400: #E9E5DE !default;
$emu-common-colors-secondary-500: #FCF4E8 !default;
$emu-common-colors-secondary-600: #ECE9E5 !default;
$emu-common-colors-secondary-700: #FFF4E9 !default;
$emu-common-colors-tertiary-500: #FFFCB8 !default;
$emu-common-colors-brand-grey-100: #DEDAD7 !default;
$emu-common-colors-brand-grey-200: #cccccc !default;
$emu-common-colors-brand-grey-300: #b3b3b3 !default;
$emu-common-colors-brand-grey-400: #b3b0ae !default;
$emu-common-colors-brand-grey-500: #787676 !default;
$emu-common-colors-brand-grey-600: #666666 !default;
$emu-common-colors-brand-grey-700: #aaaaaa !default;
$emu-common-colors-brand-grey-800: #EDE9E5 !default;
$emu-common-colors-brand-black-100: #020202 !default;
$emu-common-font-weight-600: 600 !default;
$emu-common-font-weight-bold: 700 !default;
$emu-common-font-weight-light: 300 !default;
$emu-common-font-weight-black: 900 !default;
$emu-common-font-weight-regular: 400 !default;
$emu-common-font-families-sans: 'Graphik',sans-serif !default;
$emu-common-font-families-serif: 'Teodor',sans-serif !default;
$emu-common-border-radius-small: 8px !default;
$emu-common-border-width-none: 0px !default;
$emu-common-border-width-thin: 1px !default;
$emu-common-font-sizes-display-xlarge: 136px !default;
$emu-common-font-sizes-display-large: 120px !default;
$emu-common-font-sizes-display-medium: 80px !default;
$emu-common-font-sizes-display-regular: 72px !default;
$emu-common-font-sizes-display-small: 64px !default;
$emu-common-font-sizes-display-xsmall: 48px !default;
$emu-common-font-sizes-headline-xlarge: 40px !default;
$emu-common-font-sizes-headline-large: 32px !default;
$emu-common-font-sizes-headline-medium: 24px !default;
$emu-common-font-sizes-body-large: 20px !default;
$emu-common-font-sizes-body-medium: 18px !default;
$emu-common-font-sizes-body-regular: 16px !default;
$emu-common-font-sizes-body-small: 14px !default;
$emu-common-font-sizes-captions-large: 12px !default;
$emu-common-font-sizes-captions-medium: 10px !default;
$emu-common-font-sizes-captions-regular: 8px !default;
$emu-common-font-sizes-narrow-medium: 24px !default;
$emu-common-font-sizes-narrow-large: 32px !default;
$emu-common-font-sizes-narrow-xl: 40px !default;
$emu-common-font-sizes-narrow-xxl: 48px !default;
$emu-common-font-sizes-narrow-xxxl: 64px !default;
$emu-common-font-sizes-wide-medium: 32px !default;
$emu-common-font-sizes-wide-large: 32px !default;
$emu-common-font-sizes-wide-xl: 48px !default;
$emu-common-font-sizes-wide-xxl: 72px !default;
$emu-common-font-sizes-wide-xxxl: 80px !default;
$emu-common-line-heights-custom-xs: 88% !default;
$emu-common-line-heights-custom-regular: 100% !default;
$emu-common-line-heights-custom-xxxl: 142% !default;
$emu-common-line-heights-narrow-small: 95% !default;
$emu-common-line-heights-narrow-medium: 105% !default;
$emu-common-line-heights-narrow-large: 110% !default;
$emu-common-line-heights-narrow-xl: 120% !default;
$emu-common-line-heights-narrow-xxl: 140% !default;
$emu-common-line-heights-narrow-xxxl: 145% !default;
$emu-common-line-heights-narrow-jumbo: 150% !default;
$emu-common-line-heights-wide-small: 95% !default;
$emu-common-line-heights-wide-medium: 105% !default;
$emu-common-line-heights-wide-large: 110% !default;
$emu-common-line-heights-wide-xl: 120% !default;
$emu-common-line-heights-wide-xxl: 140% !default;
$emu-common-line-heights-wide-xxxl: 145% !default;
$emu-common-line-heights-wide-jumbo: 150% !default;
$emu-common-outline-width-thin: thin !default;
$emu-common-outline-width-medium: medium !default;
$emu-common-spacing-none: 0px !default;
$emu-common-spacing-medium: 16px !default;
$emu-common-spacing-large: 32px !default;
$emu-common-spacing-brand-xs: 24px !default;
$emu-common-spacing-brand-small: 40px !default;
$emu-common-spacing-brand-medium: 56px !default;
$emu-common-spacing-brand-large: 104px !default;

$common: (
  'common': (
    'colors': (
      'white': $emu-common-colors-white,
      'black': $emu-common-colors-black,
      'transparent': $emu-common-colors-transparent,
      'darkGrey': (
        '100': $emu-common-colors-dark-grey-100
      ),
      'lightGrey': (
        '100': $emu-common-colors-light-grey-100,
        '200': $emu-common-colors-light-grey-200
      ),
      'primary': (
        '100': $emu-common-colors-primary-100,
        '200': $emu-common-colors-primary-200,
        '300': $emu-common-colors-primary-300,
        '400': $emu-common-colors-primary-400,
        '500': $emu-common-colors-primary-500,
        '600': $emu-common-colors-primary-600,
        '700': $emu-common-colors-primary-700,
        '800': $emu-common-colors-primary-800
      ),
      'secondary': (
        '100': $emu-common-colors-secondary-100,
        '200': $emu-common-colors-secondary-200,
        '300': $emu-common-colors-secondary-300,
        '400': $emu-common-colors-secondary-400,
        '500': $emu-common-colors-secondary-500,
        '600': $emu-common-colors-secondary-600,
        '700': $emu-common-colors-secondary-700
      ),
      'tertiary': (
        '500': $emu-common-colors-tertiary-500
      ),
      'brand': (
        'grey': (
          '100': $emu-common-colors-brand-grey-100,
          '200': $emu-common-colors-brand-grey-200,
          '300': $emu-common-colors-brand-grey-300,
          '400': $emu-common-colors-brand-grey-400,
          '500': $emu-common-colors-brand-grey-500,
          '600': $emu-common-colors-brand-grey-600,
          '700': $emu-common-colors-brand-grey-700,
          '800': $emu-common-colors-brand-grey-800
        ),
        'black': (
          '100': $emu-common-colors-brand-black-100
        )
      )
    ),
    'fontWeight': (
      '600': $emu-common-font-weight-600,
      'bold': $emu-common-font-weight-bold,
      'light': $emu-common-font-weight-light,
      'black': $emu-common-font-weight-black,
      'regular': $emu-common-font-weight-regular
    ),
    'fontFamilies': (
      'sans': $emu-common-font-families-sans,
      'serif': $emu-common-font-families-serif
    ),
    'borderRadius': (
      'small': $emu-common-border-radius-small
    ),
    'borderWidth': (
      'none': $emu-common-border-width-none,
      'thin': $emu-common-border-width-thin
    ),
    'fontSizes': (
      'display': (
        'xlarge': $emu-common-font-sizes-display-xlarge,
        'large': $emu-common-font-sizes-display-large,
        'medium': $emu-common-font-sizes-display-medium,
        'regular': $emu-common-font-sizes-display-regular,
        'small': $emu-common-font-sizes-display-small,
        'xsmall': $emu-common-font-sizes-display-xsmall
      ),
      'headline': (
        'xlarge': $emu-common-font-sizes-headline-xlarge,
        'large': $emu-common-font-sizes-headline-large,
        'medium': $emu-common-font-sizes-headline-medium
      ),
      'body': (
        'large': $emu-common-font-sizes-body-large,
        'medium': $emu-common-font-sizes-body-medium,
        'regular': $emu-common-font-sizes-body-regular,
        'small': $emu-common-font-sizes-body-small
      ),
      'captions': (
        'large': $emu-common-font-sizes-captions-large,
        'medium': $emu-common-font-sizes-captions-medium,
        'regular': $emu-common-font-sizes-captions-regular
      ),
      'narrow': (
        'medium': $emu-common-font-sizes-narrow-medium,
        'large': $emu-common-font-sizes-narrow-large,
        'xl': $emu-common-font-sizes-narrow-xl,
        'xxl': $emu-common-font-sizes-narrow-xxl,
        'xxxl': $emu-common-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-common-font-sizes-wide-medium,
        'large': $emu-common-font-sizes-wide-large,
        'xl': $emu-common-font-sizes-wide-xl,
        'xxl': $emu-common-font-sizes-wide-xxl,
        'xxxl': $emu-common-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'custom': (
        'xs': $emu-common-line-heights-custom-xs,
        'regular': $emu-common-line-heights-custom-regular,
        'xxxl': $emu-common-line-heights-custom-xxxl
      ),
      'narrow': (
        'small': $emu-common-line-heights-narrow-small,
        'medium': $emu-common-line-heights-narrow-medium,
        'large': $emu-common-line-heights-narrow-large,
        'xl': $emu-common-line-heights-narrow-xl,
        'xxl': $emu-common-line-heights-narrow-xxl,
        'xxxl': $emu-common-line-heights-narrow-xxxl,
        'jumbo': $emu-common-line-heights-narrow-jumbo
      ),
      'wide': (
        'small': $emu-common-line-heights-wide-small,
        'medium': $emu-common-line-heights-wide-medium,
        'large': $emu-common-line-heights-wide-large,
        'xl': $emu-common-line-heights-wide-xl,
        'xxl': $emu-common-line-heights-wide-xxl,
        'xxxl': $emu-common-line-heights-wide-xxxl,
        'jumbo': $emu-common-line-heights-wide-jumbo
      )
    ),
    'outlineWidth': (
      'thin': $emu-common-outline-width-thin,
      'medium': $emu-common-outline-width-medium
    ),
    'spacing': (
      'none': $emu-common-spacing-none,
      'medium': $emu-common-spacing-medium,
      'large': $emu-common-spacing-large,
      'brand-xs': $emu-common-spacing-brand-xs,
      'brand-small': $emu-common-spacing-brand-small,
      'brand-medium': $emu-common-spacing-brand-medium,
      'brand-large': $emu-common-spacing-brand-large
    )
  )
);
