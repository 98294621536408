#realimpact {
  .realimpact {
    &__teaser {
      @include mq('large', 'max-width') {
        .teaser-container .content-container {
          .emu-teaser__action-container {
            margin-bottom: 28px;
          }
        }
      }
    }

    &__feature,
    &__follow-feature {
      .feature {
        &__title {
          .cmp-title__text {
            @include mq('large', 'max-width') {
              font-size: var(--emu-common-font-sizes-display-xsmall);
              line-height: var(--emu-common-line-heights-narrow-medium);
              letter-spacing: -0.96px;
            }
          }
        }

        &__txt {
          @include mq('large', 'max-width') {
            p + p {
              margin-top: 23px;
            }
          }

          @include mq('large') {
            max-width: 652px; // As per design
          }
        }
      }
    }

    &__feature {
      padding-top: 96px;

      @include mq('large') {
        padding-top: 142px;
        padding-bottom: 136px;
      }

      .feature {
        &__title {
          @include mq('large') {
            margin-bottom: 48px;
          }
        }
      }
    }

    &__follow-feature {
      padding-top: 119px;
      padding-bottom: 44px;

      @include mq('large') {
        padding-top: 168px;
        padding-bottom: 116px;
      }

      .feature {
        &__title {
          margin-bottom: 28px;

          @include mq('large') {
            margin-bottom: 47px;
          }
        }

        &__txt {
          margin-bottom: 31px;

          @include mq('large') {
            margin-bottom: 30px;
          }

          p + p {
            @include mq('large') {
              margin-top: 29px;
            }
          }
        }

        &__img-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 44px;

          @include mq('large') {
            margin-top: 40px;
            padding-bottom: 26px;
          }

          img {
            @include mq('large', 'max-width') {
              width: var(--emu-common-sizing-medium);
              height: auto;
            }
          }

          .image:not(:first-child) {
            margin-left: 38px;

            @include mq('large') {
              margin-left: 40px;
            }
          }
        }
      }
    }

    &__intro-box {
      @include mq('large', 'max-width') {
        padding-bottom: 119px;
        padding-top: var(--emu-common-spacing-brand-xs);
      }

      &--receive {
        padding-bottom: 62px;

        @include mq('large') {
          padding-bottom: 66px;
        }
      }

      &--spotlight {
        padding-bottom: 61px;
        padding-top: 25px;

        @include mq('large') {
          padding-bottom: 60px;
          padding-top: 50px;
        }
      }

      .intro-box {
        &__title {
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('large') {
            margin-bottom: 27px;
          }

          @include mq('large') {
            .cmp-title__text {
              font-size: var(--emu-common-font-sizes-display-regular);
              line-height: var(--emu-common-line-heights-narrow-small);
              letter-spacing: -1.44px;
            }
          }
        }
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__section--content {
          @include mq('large') {
            padding-bottom: 50px;
          }
        }

        &__title {
          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-large);
          }
        }

        &__text {
          @include mq('large') {
            padding-right: 10px;
          }
        }

        &__disc {
          @include mq('large', 'max-width') {
            margin-top: 26px;
          }
        }
      }

      &--coaching {
        .img-txt-grid {
          &__inner {
            > .container {
              &:first-child {
                @include mq('large') {
                  margin-top: var(--emu-common-spacing-xs);
                }
              }
            }
          }

          &__section--content {
            @include mq('large') {
              padding-bottom: 52px;
            }
          }
        }
      }
    }

    &__carousel {
      &[data-component='carousel-v2'].emu-carousel[data-nav='true'] {
        padding-top: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-primary-200);

        .emu-carousel__item-container {
          @include mq('large', 'max-width') {
            margin-right: 17px;
          }
        }

        .emu-carousel__item {
          background-color: var(--emu-common-colors-transparent);
        }

        .brand-card {
          &__title {
            margin-bottom: 10px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-small);
              padding-top: 11px;
            }

            .cmp-title__text {
              font-size: var(--emu-common-font-sizes-display-medium);
              line-height: 103%;
              letter-spacing: -1.6px;

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-display-xlarge);
                line-height: 89%;
                letter-spacing: -2.72px;
              }
            }
          }

          &__content {
            min-height: 280px; // As per design

            @include mq('large') {
              min-height: 400px; // As per design
            }
          }
        }

        .tns-controls .emu-carousel__action {
          margin-top: 10px;
        }

        .tns-nav {
          @include mq('large', 'max-width') {
            top: -77px;
            left: 27px;
          }

          button {
            background-color: var(--emu-common-colors-transparent);

            &.tns-nav-active {
              background-color: var(
                --emu-component-containers-carousel-indicators-dots-color-background-active-light
              );
            }
          }
        }
      }
    }
  }
}
