.pre-footer {
  .intro-box {
    padding-bottom: 51px;

    @include mq('large') {
      padding-bottom: 65px;
      padding-top: 42px;
    }

    .cmp-title__text.emu-title__text {
      @include mq('large') {
        font-size: var(--emu-common-font-sizes-display-regular);
        letter-spacing: -1.44px;
      }
    }
  }
}
