#home {
  .home {
    // main teaser of the page
    &__teaser {
      .teaser-container {
        .content-container {
          .emu-teaser__pretitle {
            @include mq('large') {
              font-size: var(--emu-common-font-sizes-captions-large);
              letter-spacing: 0.84px;
            }
          }

          .emu-teaser__title {
            @include mq('large') {
              letter-spacing: -1.6px;
            }
          }
        }
      }
    }

    &__legend {
      padding-top: 17px;
      padding-bottom: 17px;

      @include mq('large') {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      .legend__txt {
        p {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-small);
            letter-spacing: 0.98px;
          }
        }
      }
    }

    &__mom-img-txt-grid {
      .img-txt-grid {
        &__section--content {
          @include mq('large') {
            padding-bottom: 57px;
          }
        }

        &__title {
          margin-bottom: 9px;

          @include mq('large') {
            margin-bottom: 25px;
          }

          .cmp-title__text {
            @include mq('large') {
              font-size: var(--emu-common-font-sizes-display-small);
              line-height: var(--emu-common-line-heights-narrow-small);
              letter-spacing: -1.28px;
            }
          }
        }

        &__text {
          margin-bottom: 17px;
        }
      }
    }

    // Trusted tried and proven feature - the one below main teaser
    &__trusted-feature {
      @include mq('large') {
        padding-top: 100px;
      }

      .feature {
        &__img {
          img {
            max-width: 178px;

            @include mq('large') {
              max-width: 314px;
              margin-left: 28px;
            }
          }
        }

        &__title {
          margin-top: -8px; // needed to match the design

          @include mq('large') {
            margin-top: -14px; // needed to match the design
          }
        }
      }
    }

    &__brand-carousel {
      .emu-carousel__item-container {
        @include mq('large', 'max-width') {
          margin-right: 17px;
        }
      }

      .tns-nav {
        @include mq('large', 'max-width') {
          top: -38px;
          left: 20px;
        }
      }
    }

    // Treatment feature - the one below before after component
    &__treatment-feature {
      padding-top: 88px;
      padding-bottom: var(--emu-common-spacing-brand-large);

      @include mq('large') {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: var(--emu-common-spacing-brand-large);
        padding-bottom: 159px;
      }

      &-img {
        @include mq('large') {
          margin-top: 14px;
        }

        &--rotate {
          transform: rotate(180deg);
        }
      }

      .feature {
        &__title {
          margin-bottom: 29px;

          @include mq('large') {
            margin-bottom: 46px;
            max-width: 878px; // as per figma
            margin-left: auto;
            margin-right: auto;
          }

          .cmp-title__text {
            font-size: var(--emu-common-font-sizes-display-xsmall);
            line-height: var(--emu-common-line-heights-narrow-medium);
            letter-spacing: -0.96px;

            @include mq('large') {
              font-size: 112px;
              line-height: var(--emu-common-line-heights-narrow-small);
              letter-spacing: -2.24px;
            }
          }
        }

        &__txt {
          margin-bottom: var(--emu-common-spacing-brand-xs);
          max-width: 545px;

          @include mq('large') {
            margin-bottom: var(--emu-common-sizing-large);
          }
        }
      }
    }

    // last section of home page with steps 1, 2 & 3
    &__treatment {
      &-steps {
        margin-top: 1px;
        padding-bottom: var(--emu-common-spacing-brand-medium);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-brand-medium);
        }

        &-inner {
          display: flex;
          flex-direction: column-reverse;

          @include mq('large') {
            flex-direction: row;
            max-width: 1058px; // as per figma
            margin-left: auto;
            margin-right: auto;
          }
        }

        &-img-container {
          @include mq('large') {
            margin-right: 27px;
          }
        }

        &-img {
          &--mobile {
            display: flex;
            justify-content: center;

            @include mq('large') {
              display: none;
            }
          }
        }

        &-content {
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            width: 521px; // as per figma
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
          }

          > .container {
            &:first-child {
              .home__treatment {
                &-step {
                  &-item {
                    @include mq('large', 'max-width') {
                      padding-top: 15px;
                    }
                  }

                  &-count {
                    @include mq('large', 'max-width') {
                      top: 22px;
                    }
                  }
                }

                &-title {
                  @include mq('large', 'max-width') {
                    margin-top: 9px;
                  }
                }
              }
            }

            &:not(:last-child) {
              @include mq('large') {
                position: relative;

                &::before {
                  content: '';
                  display: block;
                  height: calc(
                    100% - 79px
                  ); // height of the circle is 80 and 1px is the border width
                  width: var(--emu-common-sizing-xxs);
                  position: absolute;
                  background-color: var(--emu-common-colors-black);
                  top: 80px;
                  left: 39px;
                }
              }
            }
          }
        }
      }

      &-step {
        &-item {
          position: relative;
          padding-top: 18px;
          padding-right: 33px;
          padding-bottom: 23px;
          padding-left: var(--emu-common-spacing-brand-small);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-black);

          @include mq('large') {
            min-height: 182px; // as per figma
            padding-left: 136px;
            padding-right: var(--emu-common-spacing-none);
            padding-top: 17px;
            border-bottom: none;
            padding-bottom: 18px;
          }
        }

        &-count {
          position: absolute;
          left: 0px;
          top: 24px;
          height: 24px; // as per figma
          width: 24px; // as per figma
          border: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-black);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq('large') {
            top: 0;
            left: 0;
            height: 80px; // as per figma
            width: 80px; // as per figma
          }

          p {
            font-size: var(--emu-common-font-sizes-body-small);
            line-height: var(--emu-common-line-heights-narrow-xxxl);

            @include mq('large') {
              font-family: var(--emu-common-font-families-serif);
              font-size: var(--emu-common-font-sizes-headline-large);
              line-height: var(--emu-common-line-heights-narrow-large);
              letter-spacing: -0.64px;
              opacity: 0.9; // as per figma
            }
          }
        }

        &-title {
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }

          .cmp-title__text {
            opacity: 0.9; // as per figma
          }
        }
      }
    }

    &__ifw-img-txt-grid {
      .img-txt-grid {
        &__section--content {
          @include mq('large') {
            padding-bottom: 49px;
          }
        }

        &__title {
          margin-bottom: 10px;

          @include mq('large') {
            margin-bottom: 25px;
          }

          .cmp-title__text {
            letter-spacing: -0.64px;

            @include mq('large') {
              letter-spacing: -0.96px;
            }
          }
        }

        &__text {
          margin-bottom: 17px;

          p {
            font-weight: var(--emu-common-font-weight-regular);
            letter-spacing: -0.48px;
            line-height: var(--emu-common-line-heights-narrow-xl);
          }
        }
      }
    }
  }
}
