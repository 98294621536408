
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: #000000 !default;
$emu-semantic-colors-actions-primary-default-light: #9898FC !default;
$emu-semantic-colors-actions-primary-default-dark: #000000 !default;
$emu-semantic-colors-actions-primary-hover-light: #E1E1FE !default;
$emu-semantic-colors-actions-primary-hover-dark: #000000 !default;
$emu-semantic-colors-actions-primary-active-light: #F0F0FF !default;
$emu-semantic-colors-actions-primary-active-dark: #000000 !default;
$emu-semantic-colors-actions-primary-focus-light: #9898FC !default;
$emu-semantic-colors-actions-primary-focus-dark: #000000 !default;
$emu-semantic-colors-actions-primary-disabled-light: #ccccfe !default;
$emu-semantic-colors-actions-primary-disabled-dark: #000000 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-actions-secondary-default-light: #000000 !default;
$emu-semantic-colors-actions-secondary-default-dark: #FFFFFF !default;
$emu-semantic-colors-actions-secondary-hover-light: #666666 !default;
$emu-semantic-colors-actions-secondary-hover-dark: #FFFFFF !default;
$emu-semantic-colors-actions-secondary-active-light: #b3b3b3 !default;
$emu-semantic-colors-actions-secondary-active-dark: #FFFFFF !default;
$emu-semantic-colors-actions-secondary-focus-light: #787676 !default;
$emu-semantic-colors-actions-secondary-focus-dark: #FFFFFF !default;
$emu-semantic-colors-actions-secondary-disabled-light: #E9E5DE !default;
$emu-semantic-colors-actions-secondary-disabled-dark: #FFFFFF !default;
$emu-semantic-colors-actions-on-secondary-light: #FFFFFF !default;
$emu-semantic-colors-actions-on-secondary-dark: #000000 !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-font-sizes-display-xlarge: 136px !default;
$emu-semantic-font-sizes-display-large: 120px !default;
$emu-semantic-font-sizes-display-medium: 80px !default;
$emu-semantic-font-sizes-display-regular: 72px !default;
$emu-semantic-font-sizes-display-small: 64px !default;
$emu-semantic-font-sizes-display-xsmall: 48px !default;
$emu-semantic-font-sizes-headline-xlarge: 40px !default;
$emu-semantic-font-sizes-headline-large: 32px !default;
$emu-semantic-font-sizes-headline-medium: 24px !default;
$emu-semantic-font-sizes-body-large: 20px !default;
$emu-semantic-font-sizes-body-medium: 18px !default;
$emu-semantic-font-sizes-body-regular: 16px !default;
$emu-semantic-font-sizes-body-small: 14px !default;
$emu-semantic-font-sizes-captions-large: 12px !default;
$emu-semantic-font-sizes-captions-medium: 10px !default;
$emu-semantic-font-sizes-captions-regular: 8px !default;
$emu-semantic-font-sizes-narrow-medium: 24px !default;
$emu-semantic-font-sizes-narrow-large: 32px !default;
$emu-semantic-font-sizes-narrow-xl: 40px !default;
$emu-semantic-font-sizes-narrow-xxl: 48px !default;
$emu-semantic-font-sizes-narrow-xxxl: 64px !default;
$emu-semantic-font-sizes-wide-medium: 32px !default;
$emu-semantic-font-sizes-wide-large: 32px !default;
$emu-semantic-font-sizes-wide-xl: 48px !default;
$emu-semantic-font-sizes-wide-xxl: 72px !default;
$emu-semantic-font-sizes-wide-xxxl: 80px !default;
$emu-semantic-line-heights-custom-xs: 88% !default;
$emu-semantic-line-heights-custom-regular: 100% !default;
$emu-semantic-line-heights-custom-xxxl: 142% !default;
$emu-semantic-line-heights-narrow-small: 95% !default;
$emu-semantic-line-heights-narrow-medium: 105% !default;
$emu-semantic-line-heights-narrow-large: 110% !default;
$emu-semantic-line-heights-narrow-xl: 120% !default;
$emu-semantic-line-heights-narrow-xxl: 140% !default;
$emu-semantic-line-heights-narrow-xxxl: 145% !default;
$emu-semantic-line-heights-narrow-jumbo: 150% !default;
$emu-semantic-line-heights-wide-small: 95% !default;
$emu-semantic-line-heights-wide-medium: 105% !default;
$emu-semantic-line-heights-wide-large: 110% !default;
$emu-semantic-line-heights-wide-xl: 120% !default;
$emu-semantic-line-heights-wide-xxl: 140% !default;
$emu-semantic-line-heights-wide-xxxl: 145% !default;
$emu-semantic-line-heights-wide-jumbo: 150% !default;
$emu-semantic-font-weight-600: 600 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 40px/110% 'Teodor',sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 400 48px/105% 'Teodor',sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 400 40px/110% 'Teodor',sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 400 32px/110% 'Teodor',sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 400 24px/120% 'Teodor',sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 16px/150% 'Graphik',sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 400 80px/95% 'Teodor',sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 400 72px/95% 'Teodor',sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 400 48px/105% 'Teodor',sans-serif !default;
$emu-semantic-typography-wide-headings-large: 400 32px/110% 'Teodor',sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 400 32px/110% 'Teodor',sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 18px/145% 'Graphik',sans-serif !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1200px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1600px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-font-families-body: 'Graphik',sans-serif !default;
$emu-semantic-font-families-heading: 'Teodor',sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'default': (
            'light': $emu-semantic-colors-actions-primary-default-light,
            'dark': $emu-semantic-colors-actions-primary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-primary-hover-light,
            'dark': $emu-semantic-colors-actions-primary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-primary-active-light,
            'dark': $emu-semantic-colors-actions-primary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-primary-focus-light,
            'dark': $emu-semantic-colors-actions-primary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-primary-disabled-light,
            'dark': $emu-semantic-colors-actions-primary-disabled-dark
          )
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        ),
        'secondary': (
          'default': (
            'light': $emu-semantic-colors-actions-secondary-default-light,
            'dark': $emu-semantic-colors-actions-secondary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-secondary-hover-light,
            'dark': $emu-semantic-colors-actions-secondary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-secondary-active-light,
            'dark': $emu-semantic-colors-actions-secondary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-secondary-focus-light,
            'dark': $emu-semantic-colors-actions-secondary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-secondary-disabled-light,
            'dark': $emu-semantic-colors-actions-secondary-disabled-dark
          )
        ),
        'on-secondary': (
          'light': $emu-semantic-colors-actions-on-secondary-light,
          'dark': $emu-semantic-colors-actions-on-secondary-dark
        )
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      )
    ),
    'fontSizes': (
      'display': (
        'xlarge': $emu-semantic-font-sizes-display-xlarge,
        'large': $emu-semantic-font-sizes-display-large,
        'medium': $emu-semantic-font-sizes-display-medium,
        'regular': $emu-semantic-font-sizes-display-regular,
        'small': $emu-semantic-font-sizes-display-small,
        'xsmall': $emu-semantic-font-sizes-display-xsmall
      ),
      'headline': (
        'xlarge': $emu-semantic-font-sizes-headline-xlarge,
        'large': $emu-semantic-font-sizes-headline-large,
        'medium': $emu-semantic-font-sizes-headline-medium
      ),
      'body': (
        'large': $emu-semantic-font-sizes-body-large,
        'medium': $emu-semantic-font-sizes-body-medium,
        'regular': $emu-semantic-font-sizes-body-regular,
        'small': $emu-semantic-font-sizes-body-small
      ),
      'captions': (
        'large': $emu-semantic-font-sizes-captions-large,
        'medium': $emu-semantic-font-sizes-captions-medium,
        'regular': $emu-semantic-font-sizes-captions-regular
      ),
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'custom': (
        'xs': $emu-semantic-line-heights-custom-xs,
        'regular': $emu-semantic-line-heights-custom-regular,
        'xxxl': $emu-semantic-line-heights-custom-xxxl
      ),
      'narrow': (
        'small': $emu-semantic-line-heights-narrow-small,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl,
        'jumbo': $emu-semantic-line-heights-narrow-jumbo
      ),
      'wide': (
        'small': $emu-semantic-line-heights-wide-small,
        'medium': $emu-semantic-line-heights-wide-medium,
        'large': $emu-semantic-line-heights-wide-large,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl,
        'jumbo': $emu-semantic-line-heights-wide-jumbo
      )
    ),
    'fontWeight': (
      '600': $emu-semantic-font-weight-600,
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    ),
    'sizing': (
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'tablet': $emu-semantic-sizing-breakpoints-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      )
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium
    ),
    'borderWidth': (
      'none': $emu-semantic-border-width-none
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading
    )
  )
);
