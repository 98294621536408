.aaaem-accordion {
  dt,
  .aaaem-accordion__header,
  .aaaem-accordion__button {
    -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  }

  .aaaem-accordion {
    &__header {
      border-top: var(
          --emu-component-lists-accordion-item-border-width-bottom-open
        )
        solid
        var(
          --emu-component-lists-accordion-item-color-border-bottom-closed-light
        );
      border-bottom: none;
      min-height: 88px; // to match the design
      display: flex;
      align-items: center;
      transition: all 0.2s ease-out;

      @include mq('large') {
        padding-top: 28px;
        padding-bottom: 22px;
        min-height: 104px; // to match the design
      }

      &.-ba-is-active {
        .aaaem-accordion__icon {
          background-color: var(--emu-common-colors-primary-500);
        }
      }
    }

    &__title {
      line-height: 120%; // to match figma
      letter-spacing: -0.48px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-headline-large);
        line-height: 110%;
        letter-spacing: -0.64px;
      }
    }

    &__panel {
      transform: translateY(-16px);

      @include mq('large') {
        transform: translateY(0);
      }
    }
  }

  .plus-minus-icon {
    .aaaem-accordion__icon {
      flex: none;
      border: var(--emu-common-border-width-medium) solid
        var(--emu-common-colors-primary-500);
      border-radius: 50%;

      @include mq('large') {
        align-self: auto;
      }

      &::before,
      &::after {
        height: var(--emu-common-sizing-xs); // as per figma
        top: 0;
        bottom: 0;

        @include mq('large') {
          height: 11px; // to match figma
        }
      }
    }
  }
}
