#ba {
  .ba {
    // main hero section of the page
    &__hero-teaser {
      .content-container {
        @include mq('large', 'max-width') {
          padding-bottom: 25px;
        }
      }
    }

    // Before & After Title
    &__intro-box {
      @include mq('large', 'max-width') {
        padding-bottom: 34px;
        padding-top: 23px;
      }

      &-title {
        margin-bottom: 30px;

        @include mq('large') {
          margin-bottom: 28px;
        }
      }
    }

    // Before & After Model section with images and modal trigger button
    &__ba-item {
      &-title {
        margin-bottom: var(--emu-common-spacing-medium);
        padding-top: 17px;

        @include mq('large') {
          margin-bottom: 38px;
          padding-top: 15px;
        }
      }

      &-cta {
        margin-bottom: 54px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      .ba-item__disc {
        @include mq('large') {
          padding-right: 21px;
        }
      }
    }

    // Find a Provider Promo card
    &__promo {
      padding-top: 36px;

      @include mq('large') {
        padding-top: 76px;
      }

      &-content {
        @include mq('large') {
          padding-bottom: 79px;
        }
      }

      &-txt {
        p {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-large);
            line-height: var(--emu-common-line-heights-narrow-xxxl);
          }
        }
      }
    }

    // References section above ISI
    &__references {
      padding-bottom: 36px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-none);
      }

      &-txt {
        p + p {
          margin-top: 18px;
        }
      }
    }
  }
}
