.button {
  .emu-button.emu-button-v2.emu-button-v2 {
    transition: all var(--emu-common-other-time-transition-short) linear;
    outline-width: 0;
    -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

    &:active {
      outline: none;
    }

    &[disabled] {
      .cmp-button__text,
      .cmp-button__icon-text {
        opacity: 0.5;
      }
    }

    &:focus {
      outline-style: solid;
    }

    &.emu-button__primary-filled,
    &:is(button) {
      &:focus {
        outline-width: var(
          --emu-component-actions-button-outline-width-primary-filled
        );
      }
    }

    &.emu-button__primary-outline {
      &:focus {
        outline-width: var(
          --emu-component-actions-button-outline-width-primary-outline
        );
      }
    }

    &.emu-button__secondary-filled {
      &:focus {
        outline-width: var(
          --emu-component-actions-button-outline-width-secondary-filled
        );
      }
    }

    &.emu-button__secondary-outline {
      &:focus {
        outline-width: var(
          --emu-component-actions-button-outline-width-secondary-outline
        );
      }
    }

    .cmp-button__text,
    .cmp-button__icon-text {
      letter-spacing: -0.2px;
      transition: all var(--emu-common-other-time-transition-short) linear;
    }
  }
}
