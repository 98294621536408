.brand-card {
  width: 320px; // As per figma

  @include mq('large') {
    width: 414px; // As per figma
  }

  &__content {
    padding-top: 31px;
    padding-right: var(--emu-common-spacing-brand-xs);
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: 25px;

    min-height: 367px; // As per figma
    display: flex;
    flex-direction: column;

    @include mq('large') {
      padding-right: var(--emu-common-spacing-large);
      padding-left: var(--emu-common-spacing-large);
    }
  }

  &__pretitle {
    margin-bottom: 22px;
  }

  &__title {
    padding-top: 9px;
    margin-bottom: var(--emu-common-spacing-medium);

    sup {
      top: 1px;
    }
  }

  &__subtitle {
    margin-bottom: 18px;

    @include mq('large') {
      margin-bottom: 17px;
    }

    &:not([class*='u-typography--']) {
      font-size: var(--emu-common-font-sizes-body-small);
    }
  }

  &__asset {
    img {
      width: 100%;
    }
  }

  &__txt {
    margin-bottom: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      margin-bottom: 25px;
    }
  }

  &__cta {
    margin-bottom: 11px;
  }

  &--expandable {
    min-height: 494px; // As per design
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    @include mq('x-large') {
      min-height: 648px; // As per design
    }

    > .container {
      background-color: inherit;
    }

    > .container:has(.brand-card__content),
    > .container.js-has-brand-card__content {
      flex: 1;
      display: flex;
    }

    .brand-card {
      &__asset {
        transition: all var(--emu-common-other-time-transition-base) ease-out;
        max-height: 600px; // biggest possible value for the card
      }

      &__content {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
        min-height: 292px; // As per design
        padding-top: var(--emu-common-spacing-medium);
        flex: 1;
        transition: all var(--emu-common-other-time-transition-base) ease-out;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-medium);
        }

        @include mq('x-large') {
          min-height: 408px; // As per design
        }
      }

      &__title {
        margin-bottom: 3px;
        @include mq('large') {
          margin-bottom: 5px;
        }
      }

      &__txt {
        font-size: var(--emu-common-font-sizes-body-small);
        margin-bottom: 5px;
        max-height: 63px; // As per design
        line-clamp: 3;
        -webkit-line-clamp: 3;
        overflow: hidden;
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        transition: all 2s linear;

        &.brand-card__text--expand {
          max-height: 800px;
          line-clamp: unset;
          -webkit-line-clamp: unset;
        }
      }

      &__cta {
        span {
          text-decoration: underline;
        }
      }
    }

    &-inflate {
      padding-top: var(--emu-common-spacing-none);

      .brand-card {
        &__asset {
          max-height: 0;
          overflow: hidden;
          transition: all var(--emu-common-other-time-transition-base) ease-in;
        }

        &__content {
          background-color: inherit !important; // to override the default important coming from the utils
          transition: all var(--emu-common-other-time-transition-base) ease-out;
        }
      }
    }
  }
}
