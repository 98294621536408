#realimpact-v2 {
  .realimpact {
    &__teaser {
      .emu-teaser {
        &__title {
          font-size: var(--emu-common-font-sizes-narrow-xl);
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: -0.8px;

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-display-regular);
            line-height: var(--emu-common-line-heights-narrow-small);
            letter-spacing: -1.44px;
          }
        }

        &__description {
          sup {
            font-size: 100%;
            vertical-align: middle;
            top: 0;
          }
        }

        &__disclaimer {
          @include mq('large', 'max-width') {
            margin-top: 67px;
          }
        }
      }

      .cmp-teaser__image--mobile {
        @include mq('large', 'max-width') {
          display: none;
        }
      }
    }

    &__image-container {
      @include mq('large') {
        padding-top: 15px;
      }
    }

    &__intro-box {
      padding-bottom: 34px;

      @include mq('large') {
        padding-bottom: 66px;
        padding-top: 45px;
      }

      .intro-box__title {
        @include mq('large', 'max-width') {
          br {
            display: none;
          }
        }

        @include mq('large') {
          max-width: 876px; // As per design
        }
      }
    }

    &__three-card-grid {
      padding-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        justify-content: space-between;
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: 57px;
      }

      > .container {
        @include mq('large') {
          width: calc(33.333% - 28px); // As per design
        }
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__title {
          margin-bottom: 15px;

          @include mq('large') {
            margin-bottom: 31px;
          }
        }

        &__disc {
          margin-top: 68px;
        }

        &__image {
          margin-bottom: var(--emu-common-spacing-brand-xs);

          @include mq('large') {
            margin-bottom: 41px;
          }

          @include mq('large', 'max-width') {
            img {
              max-height: 42px; // As per design
            }
          }
        }

        &__section--content {
          @include mq('large') {
            padding-bottom: 50px;
          }
        }
      }

      &--readlines {
        @include mq('large') {
          margin-top: 7px;

          .img-txt-grid {
            &__title {
              margin-bottom: 31px;
            }

            &__section--content {
              padding-bottom: 50px;
            }
          }
        }

        .img-txt-grid {
          @include mq('large', 'max-width') {
            &__disc {
              margin-top: 26px;
            }
          }
        }
      }

      &--network {
        .img-txt-grid {
          @include mq('large', 'max-width') {
            &__disc {
              margin-top: 25px;
            }
          }
        }
      }
    }

    &__brand-card {
      .brand-card {
        &__content {
          min-height: 281px; // As per design

          @include mq('large') {
            min-height: 400px; // As per design
          }
        }

        &__title {
          padding-top: 12px;
          margin-bottom: 13px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-xs);
            padding-top: 6px;
          }

          .cmp-title__text {
            font-size: var(--emu-common-font-sizes-display-medium);
            line-height: var(--emu-common-line-heights-narrow-small);
            letter-spacing: -1.6px;

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-display-xlarge);
              letter-spacing: -2.72px;
            }
          }
        }

        &__txt p {
          @include mq('large', 'max-width') {
            font-size: var(--emu-common-font-sizes-body-small);
            line-height: var(--emu-common-line-heights-narrow-jumbo);
            letter-spacing: -0.05px;
          }
        }
      }

      &--spacing {
        .brand-card {
          &__content {
            @include mq('large', 'max-width') {
              min-height: 302px; // As per design
            }
          }
        }
      }
    }

    &__intrepreneurs-intro-box {
      padding-bottom: 49px;

      @include mq('large') {
        padding-bottom: 97px;
        padding-top: 45px;
      }

      .intro-box {
        &__title {
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-bottom: 21px;
          }
        }

        &__text {
          @include mq('large') {
            max-width: 652px; // As per design
          }

          p {
            font-size: var(--emu-common-font-sizes-headline-medium);
            line-height: 123%;
            letter-spacing: -0.48px;
          }
        }
      }
    }

    &__apply-intro-box {
      .intro-box {
        &__title {
          @include mq('large', 'max-width') {
            font-size: var(--emu-common-font-sizes-display-xsmall);
            line-height: var(--emu-common-line-heights-narrow-medium);
            letter-spacing: -0.96px;
          }

          @include mq('large') {
            margin-bottom: 29px;
          }
        }

        &__text {
          @include mq('large') {
            max-width: 654px; // As per design
          }
        }

        &__cta {
          line-height: 18px;
        }
      }
    }

    &__feature {
      padding-top: 96px;

      @include mq('large') {
        padding-top: 146px;
      }

      .feature {
        &__title .cmp-title__text {
          @include mq('large', 'max-width') {
            font-size: var(--emu-common-font-sizes-display-xsmall);
            line-height: var(--emu-common-line-heights-narrow-medium);
            letter-spacing: -0.96px;
          }
        }
      }
    }
  }
}
