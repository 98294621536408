.emu-consent-banner {
  max-width: calc(100vw - 16px);
  border-radius: var(--emu-common-border-radius-small);

  @include mq('medium') {
    max-width: var(--emu-component-layers-cookie-consent-banner-max-width);
    margin-bottom: 22px;
  }

  > div {
    align-items: flex-start;
    max-width: none;
    flex-direction: column;
  }

  a,
  p,
  button,
  b {
    font-family: var(--emu-semantic-font-families-body);
    font-size: var(--emu-common-font-sizes-captions-large);
    line-height: var(--emu-common-line-heights-narrow-jumbo);
  }

  button {
    letter-spacing: 0.84px;
    cursor: pointer;
  }

  &__cookie-notice {
    width: calc(100% - 30px);
    max-height: calc(100dvh - 144px);
    overflow: auto;

    button[data-ref] {
      font-weight: var(--emu-common-font-weight-regular);
      letter-spacing: normal;
    }
  }

  &__close-button {
    margin: var(--emu-common-spacing-none);

    &-container {
      margin: var(--emu-common-spacing-none);
      top: 10px;
      right: 10px;
      height: auto;

      @include mq('medium') {
        top: -6px;
        right: -6px;
      }

      path {
        fill: var(--emu-common-colors-white);
      }
    }
  }

  &__settings-cta,
  &__wa-buttons-wrapper > button {
    width: 100%;
    max-width: none;

    @include mq('medium') {
      margin: var(
          --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide
        )
        var(
          --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide
        )
        var(
          --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide
        )
        var(
          --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide
        );
    }
  }

  .emu-consent-banner__wa-buttons-wrapper {
    margin-top: var(
      --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow
    );

    @include mq('medium') {
      margin-top: var(
        --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide
      );
    }

    > button {
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      color: var(--emu-common-colors-black);
    }
  }
}
