[data-component='text'][class*='u-typography--'],
[data-component='title'][class*='u-typography--'] {
  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a {
    font: inherit;
    letter-spacing: inherit;
  }
}

[data-component='text'][class*='u-font-weight--'],
[data-component='title'][class*='u-font-weight--'] {
  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a {
    font-weight: inherit !important; // needed to override any defaults added
  }
}

[data-component='text'][class*='u-font-family--'],
[data-component='title'][class*='u-font-family--'] {
  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a {
    font-family: inherit !important; // needed to override any defaults added
  }
}

// ********************************************************************************** //
// ** START: typography utilities
// ********************************************************************************** //
.u-typography--h1,
.u-typography--h1-mobile-lg {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h1-mobile-lg {
  @include mq('large', 'max-width') {
    font: var(--emu-semantic-typography-narrow-headings-xxl);
  }
}

.u-typography--h2,
.u-typography--h2-mobile-sm {
  font: var(--emu-semantic-typography-narrow-headings-xxl);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h2-mobile-sm {
  @include mq('large', 'max-width') {
    font: var(--emu-semantic-typography-narrow-headings-xl);
  }
}

.u-typography--h3,
.u-typography--h3-mobile-sm {
  font: var(--emu-semantic-typography-narrow-headings-xl);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h3-mobile-sm {
  @include mq('large', 'max-width') {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5 {
  font: var(--emu-semantic-typography-narrow-headings-medium);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--h6 {
  font-size: var(--emu-common-font-sizes-headline-medium);
  line-height: var(--emu-common-line-heights-narrow-large);
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
  letter-spacing: -0.1px;
}

.u-typography--intro-heading {
  font: var(--emu-semantic-typography-narrow-headings-xl);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--body-lg {
  font-size: var(--emu-common-font-sizes-body-medium);
  line-height: var(--emu-common-line-heights-narrow-xxxl);

  @include mq('large') {
    font-size: var(--emu-common-font-sizes-body-large);
  }

  &-desktop {
    @include mq('large') {
      font-size: var(--emu-common-font-sizes-body-large);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
    }
  }
}

.u-typography--body-sm {
  font-size: var(--emu-common-font-sizes-body-small);
  line-height: var(--emu-common-line-heights-narrow-jumbo);

  @include mq('large') {
    font-size: var(--emu-common-font-sizes-body-regular);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-common-font-sizes-captions-large);
  line-height: var(--emu-common-line-heights-narrow-jumbo);
}

.u-typography--body-caption {
  font-size: var(--emu-common-font-sizes-captions-medium);
  line-height: var(--emu-common-line-heights-narrow-jumbo);
}

.u-typography--body-caption-lg {
  font-size: var(--emu-common-font-sizes-body-small);
  line-height: var(--emu-common-line-heights-narrow-jumbo);
}

.u-typography--body-mobile {
  font-size: var(--emu-common-font-sizes-body-regular);
  line-height: var(--emu-common-line-heights-narrow-jumbo);
}

// this class may be used only when it is really needed to override any default styles from a component.
// Added !important to facilitate the same.
.u-typography--body {
  font-size: var(--emu-common-font-sizes-body-regular) !important;
  line-height: var(--emu-common-line-heights-narrow-jumbo) !important;

  @include mq('large') {
    font-size: var(--emu-common-font-sizes-body-medium) !important;
    line-height: var(--emu-common-line-heights-wide-xxxl) !important;
  }
}

// ********************************************************************************** //
// ** END: Typography utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: general text utilities
// ********************************************************************************** //

.u-text--overline {
  border-top: var(--emu-common-border-width-thin) solid currentColor;
  padding-top: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-top: 13px;
  }
}

.u-list-style-none ul {
  list-style: none;
}

.u-list-no-space ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

// ********************************************************************************** //
// ** END: general text utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: CTA utilities
// ********************************************************************************** //
.u-cta--as-link {
  &.emu-button.emu-button-v2.emu-button-v2.emu-button-v2 {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: var(--emu-common-colors-transparent);
      color: inherit;
      border-color: var(--emu-common-colors-transparent);
      outline: none;
      padding: var(--emu-common-spacing-none);
    }
  }
}

.u-cta--on-dark {
  &.emu-button.emu-button-v2.emu-button-v2.emu-button-v2 {
    border-color: var(--emu-common-colors-white);
    color: var(--emu-common-colors-white);

    &:hover {
      background-color: #232323; // used only once
      color: var(--emu-common-colors-white);
    }

    &:focus {
      outline-color: var(--emu-common-colors-white);
      color: var(--emu-common-colors-white);
    }

    &:active {
      background-color: #333; // used only once
      color: var(--emu-common-colors-white);
    }
  }
}

.u-cta--solid-white {
  &,
  &:focus,
  &:hover,
  &:active {
    background-color: var(
      --emu-common-colors-white
    ) !important; // to make sure that it is not overridden anywhere. Use case is in a teaser where there are overriding styles
    color: var(
      --emu-common-colors-black
    ) !important; // to make sure that it is not overridden anywhere. Use case is in a teaser where there are overriding styles
  }

  &:focus {
    outline-color: var(
      --emu-common-colors-white
    ) !important; // to make sure that it is not overridden anywhere. Use case is in a teaser where there are overriding styles
  }
}

.u-cta--no-letter-spacing {
  &.emu-button.emu-button-v2.emu-button-v2.emu-button-v2 {
    .cmp-button__text,
    .cmp-button__icon-text {
      letter-spacing: normal;
    }
  }

  &-mobile {
    &.emu-button.emu-button-v2.emu-button-v2.emu-button-v2 {
      .cmp-button__text,
      .cmp-button__icon-text {
        @include mq('large', 'max-width') {
          letter-spacing: normal;
        }
      }
    }
  }

  &-desktop {
    &.emu-button.emu-button-v2.emu-button-v2.emu-button-v2 {
      .cmp-button__text,
      .cmp-button__icon-text {
        @include mq('large') {
          letter-spacing: normal;
        }
      }
    }
  }
}

.u-cta--line-height-medium {
  &.emu-button.emu-button-v2.emu-button-v2 {
    .cmp-button__text,
    .cmp-button__icon-text {
      line-height: var(--emu-common-line-heights-custom-xxxl);
    }
  }

  &-mobile {
    &.emu-button.emu-button-v2.emu-button-v2 {
      .cmp-button__text,
      .cmp-button__icon-text {
        @include mq('large', 'max-width') {
          line-height: var(--emu-common-line-heights-custom-xxxl);
        }
      }
    }
  }

  &-desktop {
    &.emu-button.emu-button-v2.emu-button-v2 {
      .cmp-button__text,
      .cmp-button__icon-text {
        @include mq('large') {
          line-height: var(--emu-common-line-heights-custom-xxxl);
        }
      }
    }
  }
}

.u-cta--spacing-large {
  &.emu-button.emu-button-v2.emu-button-v2 {
    padding: var(--emu-common-spacing-medium);
  }

  &-mobile {
    &.emu-button.emu-button-v2.emu-button-v2 {
      @include mq('large', 'max-width') {
        padding: var(--emu-common-spacing-medium);
      }
    }
  }

  &-desktop {
    &.emu-button.emu-button-v2.emu-button-v2 {
      @include mq('large') {
        padding: var(--emu-common-spacing-medium);
      }
    }
  }
}

.u-cta--fsz-large {
  &.emu-button.emu-button-v2.emu-button-v2 {
    .cmp-button__text,
    .cmp-button__icon-text {
      font-size: var(--emu-common-font-sizes-body-regular);
    }
  }

  &-mobile {
    &.emu-button.emu-button-v2.emu-button-v2 {
      .cmp-button__text,
      .cmp-button__icon-text {
        @include mq('large', 'max-width') {
          font-size: var(--emu-common-font-sizes-body-regular);
        }
      }
    }
  }

  &-desktop {
    &.emu-button.emu-button-v2.emu-button-v2 {
      .cmp-button__text,
      .cmp-button__icon-text {
        @include mq('large') {
          font-size: var(--emu-common-font-sizes-body-regular);
        }
      }
    }
  }
}

// ********************************************************************************** //
// ** END: CTA utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Visibility utilities
// ********************************************************************************** //

.u-hide {
  display: none !important; // to make sure and override any other given styles

  @include aem-editor-view {
    display: initial !important; // to make sure and override any other given styles
  }
}

.u-hide-overflow {
  overflow: hidden;
  height: 100vh;
}

// ********************************************************************************** //
// ** END: Visibility utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Font Weight utilities
// ********************************************************************************** //
.u-font-weight--semibold {
  font-weight: var(--emu-common-font-weight-600);
}

// ********************************************************************************** //
// ** END: Font Weight utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Container utilities
// ********************************************************************************** //
.u-container--max-width {
  @include max-container;
}

// ********************************************************************************** //
// ** END: Container utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Color utilities
// ********************************************************************************** //
[data-component='title'][class*='u-text-color'],
[data-component='text'][class*='u-text-color'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  button,
  a {
    color: inherit !important;
  }
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--black-200 {
  color: var(--emu-common-colors-brand-black-200);
}

.u-bg--black {
  background: var(--emu-common-colors-black) !important;
}

.u-bg--white {
  background: var(--emu-common-colors-white) !important;
}

.u-bg--primary-200 {
  background: var(--emu-common-colors-primary-200) !important;
}

.u-bg--primary-500 {
  background: var(--emu-common-colors-primary-500) !important;
}

.u-bg--primary-600 {
  background: var(--emu-common-colors-primary-600) !important;
}

.u-bg--primary-800 {
  background: var(--emu-common-colors-primary-800) !important;
}

.u-bg--secondary-500 {
  background: var(--emu-common-colors-secondary-500) !important;
}

.u-bg--secondary-600 {
  background: var(--emu-common-colors-secondary-600) !important;
}

.u-bg--secondary-700 {
  background: var(--emu-common-colors-secondary-700) !important;
}

.u-bg--grey-800 {
  background: var(--emu-common-colors-brand-grey-800) !important;
}

.u-bg--grey-900 {
  background: var(--emu-common-colors-brand-grey-900) !important;
}

.u-bg--tertiary-500 {
  background: var(--emu-common-colors-tertiary-500) !important;
}

// ********************************************************************************** //
// ** END: Color utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Font Family Utilities
// ********************************************************************************** //
.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

// ********************************************************************************** //
// ** END: Font Family Utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Global Position Utilities
// ********************************************************************************** //
// use only u-mt-auto. js-has-u-mt-auto will be added by JS if :has is not supported by the browser
.js-has-u-mt-auto,
div:has(> .u-mt-auto) {
  margin-top: auto;
}

// use only u-right-align. js-has-u-right-align will be added by JS if :has is not supported by the browser
.js-has-u-right-align,
div:has(> .u-right-align) {
  display: flex;
  flex-direction: column;
}

.u-right-align {
  align-self: flex-end;
}

// use only u-center-align. js-has-u-center-align will be added by JS if :has is not supported by the browser
.js-has-u-center-align,
div:has(> .u-center-align) {
  display: flex;
  flex-direction: column;
}

.u-center-align {
  align-self: center;
}

// ********************************************************************************** //
// ** END: Global Position Utilities
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Modal Black Background Utility
// ********************************************************************************** //

.u-modal--black-bg {
  .modal-wrapper,
  .modal-content {
    background-color: var(--emu-common-colors-black);
  }
}

// ********************************************************************************** //
// ** END: Modal Black Background Utility
// ********************************************************************************** //

// ********************************************************************************** //
// ** START: Carousel Navigation Dots at Bottom Utility
// ********************************************************************************** //
[data-component='carousel-v2'] {
  &.emu-carousel {
    &.u-carousel--nav-at-bottom {
      .tns-nav {
        top: unset;
        left: 50%;
        bottom: 27px;
        width: 100%;
        right: auto;
        left: auto;
        transform: none;

        @include mq('medium') {
          bottom: -121px;
        }
      }
    }

    &.u-carousel--nav-light {
      .tns-nav {
        button {
          &.tns-nav-active {
            background-color: var(
              --emu-component-containers-carousel-indicators-dots-color-background-active-dark
            );
            border-color: var(
              --emu-component-containers-carousel-indicators-dots-color-border-active-dark
            );
          }
        }
      }
    }

    &.u-carousel--disabled {
      .emu-carousel__content {
        transform: none !important; // making sure to override the styles added by Tiny Slider JS
      }

      .tns-ovh {
        height: auto !important;
      }

      .tns-controls,
      .tns-nav {
        display: none;
      }
    }
  }
}

// ********************************************************************************** //
// ** END: Carousel Navigation Dots at Bottom Utility
// ********************************************************************************** //

.u-img-full-width:not(.emu-teaser) img,
.u-img-full-width.emu-teaser .teaser-image-container img {
  width: 100% !important; // to make sure that this overrides every other style
  height: auto !important; // to make sure that this overrides every other style
  max-width: none !important; // to make sure that this overrides every other style
  max-height: none !important; // to make sure that this overrides every other style
  object-fit: cover !important; // to make sure that this overrides every other style
  object-position: center !important; // to make sure that this overrides every other style
}
