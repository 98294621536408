#faq {
  // Main Teaser section
  .intro-box {
    &.intro-box--as-hero {
      padding-top: 23px;

      @include mq('large') {
        padding-top: 46px;
        padding-bottom: 112px;
      }
    }

    &__title {
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('large') {
        margin-bottom: 27px;
      }
    }
  }

  // Actual FAQ section with questions and answers
  .faq {
    padding-bottom: 84px;

    @include mq('large') {
      padding-bottom: var(--emu-common-spacing-none);
    }

    &__section {
      &--left {
        padding-bottom: var(--emu-common-spacing-brand-xs);

        @include mq('large') {
          padding-top: 45px;
        }
      }

      &--right {
        @include mq('large') {
          padding-top: 55px;
          padding-bottom: 53px;
        }
      }
    }

    &__title {
      sup {
        top: 0.36em;
        font-size: 100%;

        @include mq('large') {
          top: 0.01em;
          font-size: 63%;
        }
      }
    }

    &__accordion {
      .aaaem-accordion__panel {
        @include mq('large') {
          transform: translateY(-9px);
        }

        .js-badger-accordion-panel-inner {
          @include mq('large') {
            padding-bottom: 24px;
          }
        }
      }
    }
  }

  // References at the end of the page, above ISI
  .references {
    padding-bottom: 52px;
  }
}
