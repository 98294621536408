@mixin display-sub-nav {
  > ul {
    display: block;
  }

  > a > span,
  > span {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.navigationlist {
  .emu-navigation {
    &__content-wrapper {
      position: relative;
    }

    &__item {
      font-size: var(--emu-common-font-sizes-body-large);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      margin-top: var(
        --emu-common-spacing-none
      ); // to overwrite default styles coming from g-base file

      @include mq('large') {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: var(--emu-common-font-sizes-body-small);
        line-height: 20px;
        letter-spacing: -0.2px;
      }

      a {
        -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

        @include mq('large') {
          padding: var(--emu-common-spacing-none);
        }
      }
    }

    &__item-parent {
      // increasing specificity
      &.emu-navigation__item {
        ul {
          display: none;
        }

        &.show-sub-nav {
          @include mq('large', 'max-width') {
            @include display-sub-nav;
          }
        }

        &:hover {
          @include mq('large') {
            @include display-sub-nav;
          }
        }
      }

      > a > span,
      > span {
        padding: var(--emu-common-spacing-none);
        position: relative;
        display: flex;
        align-items: center;

        &::after {
          content: url('../../assets/images/chevron-down.png');
          display: inline;
          position: absolute;
          height: 20px; // as per figma
          width: 20px; // as per figma
          right: 0;
          transition: all var(--emu-common-other-time-transition-short) linear;

          @include mq('large') {
            position: relative;
            margin-left: 3px;
          }
        }
      }
    }

    &__menu--level-0 {
      @include mq('medium') {
        flex-direction: column;
      }

      @include mq('large') {
        flex-direction: row;
      }
    }

    &__menu--level-1 {
      @include mq('medium') {
        position: static;
      }

      @include mq('large') {
        position: absolute;
        min-width: 216px; // to match the design
        max-width: 250px; // as per figma
        padding-top: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-small);
        top: 100%;
        border-radius: var(--emu-common-border-radius-small);
        box-shadow: 0px 21px 12px 0px rgba(0, 0, 0, 0.02),
          0px 12px 7px 0px rgba(0, 0, 0, 0.03),
          0px 5px 5px 0px rgba(0, 0, 0, 0.04),
          0px 1px 10px 0px rgba(0, 0, 0, 0.08); // as per figma
      }

      li {
        max-width: 90%;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);
          padding-top: 6px;
          padding-bottom: 6px;
          max-width: none;
        }

        & + li {
          a {
            padding-top: 9px;

            @include mq('large') {
              padding-top: var(--emu-common-spacing-none);
            }
          }
        }

        a {
          padding-left: 36px;

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}
