.journey {
  &.aaaem-container {
    .journey {
      &__banner {
        display: flex;
        flex-direction: column;
        position: relative;

        @include mq('large') {
          flex-direction: row;
        }

        &-content {
          padding-top: 30px;
          padding-right: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-large);
          padding-left: var(--emu-common-spacing-medium);

          @include mq('large') {
            display: flex;
            flex-direction: column;
            padding-top: 53px;
            padding-bottom: 57px;
            padding-left: var(--emu-common-spacing-brand-medium);
            min-height: 781px; // to match the design
          }

          > .container {
            &:last-child {
              margin-top: auto;
            }
          }
        }

        &-section {
          &--top {
            margin-bottom: var(--emu-common-spacing-large);
          }
        }

        > .container:first-child {
          max-width: 640px; // As per design
          position: relative;
          z-index: var(--emu-common-other-z-index-layer);
        }

        &-pretitle {
          margin-bottom: 23px;
          letter-spacing: 1px;

          @include mq('large') {
            margin-bottom: 35px;
            letter-spacing: 1.1px;
          }
        }

        &-title {
          margin-bottom: 7px;

          @include mq('large', 'max-width') {
            letter-spacing: -0.2px;
          }

          @include mq('large') {
            margin-bottom: 20px;
            letter-spacing: -1.2px;
          }
        }

        &-txt {
          letter-spacing: -0.1px;
        }

        &-detail {
          margin-bottom: 17px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-brand-xs);
          }

          &-label {
            margin-bottom: var(--emu-common-spacing-xxs);

            @include mq('large') {
              letter-spacing: 0.8px;
              margin-bottom: var(--emu-common-spacing-xxs);
            }
          }
        }

        &-img {
          img {
            margin: auto;

            @include mq('large') {
              position: absolute;
              left: 0;
              top: 1px; // intentional to match the design
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 0;
            }

            @include aem-editor-view {
              position: static;
            }
          }
        }
      }

      &__summary {
        .emu-carousel {
          &.emu-carousel {
            border-top: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
            padding-top: 54px;
            padding-bottom: var(--emu-common-spacing-none);

            @include mq('large') {
              padding-top: var(--emu-common-spacing-none);
              border-top: none;
            }

            .tns-ovh {
              transition: all var(--emu-common-other-time-transition-base);
            }

            .tns-nav {
              left: 19px;

              @include mq('large') {
                left: auto;
                right: 20px;
              }
            }

            .tns-outer {
              border: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-black);
            }

            .js-slide-active {
              .journey__card-content .text {
                opacity: 1;
              }
            }

            .emu-carousel__content {
              .tns-item:not(:last-child) {
                border-right: var(--emu-common-border-width-thin) solid
                  var(--emu-common-colors-black);
              }
            }

            // making the carousel and its children 100% height to match the design
            // grouped them all under one media query intentionally
            @include mq('medium') {
              .emu-carousel__content {
                display: flex;

                .carouselslide,
                .emu-carousel__item-container,
                .emu-carousel__item-container > .container,
                .journey__card {
                  height: 100%;
                }

                .journey__card {
                  display: flex;
                  flex-direction: column;

                  > .container {
                    &:last-child {
                      flex: 1;
                    }
                  }

                  &-content {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      &__card {
        @include mq('medium') {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        > .container {
          @include mq('medium') {
            &:last-child {
              flex: 1;
            }
          }
        }

        &-img {
          img {
            width: 100%;
            object-fit: cover;
          }
        }

        &-content {
          min-height: 262px; // as per figma
          padding-top: var(--emu-common-spacing-brand-xs);
          padding-bottom: var(--emu-common-spacing-large);
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);

          @include mq('medium') {
            height: 100%;
          }

          @include mq('large') {
            min-height: 419px; // as per figma
            padding-top: 48px;
            padding-bottom: var(--emu-common-spacing-brand-xs);
            padding-left: var(--emu-common-spacing-brand-medium);
            padding-right: var(--emu-common-spacing-brand-medium);
          }
        }

        &-title {
          margin-bottom: 7px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }

          p {
            font-size: var(--emu-common-font-sizes-body-large);
            line-height: var(--emu-common-line-heights-narrow-xxxl);
          }
        }

        &-txt {
          margin-bottom: 27px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-brand-xs);
          }

          b {
            font-size: var(--emu-common-font-sizes-body-regular);
          }

          ul {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            padding-left: 21px;
            list-style: none;

            @include mq('large') {
              margin-top: var(--emu-common-spacing-xxs);
            }

            li {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                left: -12px;
                top: 9px;
                width: 3px;
                height: 3px;
                background-color: currentColor;
                border-radius: 50%;
              }
            }

            li + li {
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }
  }
}
