.legend {
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);

  @include mq('large') {
    padding-top: 9.5px;
    padding-bottom: 9.5px;
  }

  &__txt {
    p {
      font-size: var(--emu-common-font-sizes-captions-medium);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      letter-spacing: 0.7px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-captions-large);
        letter-spacing: 0.84px;
      }
    }
  }
}
