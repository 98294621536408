[data-component='modal'] {
  .modal-wrapper.is-open {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    position: static;
    margin-left: 16.5px;
    margin-right: 16.5px;
    margin-top: auto;
    margin-bottom: auto;
    max-width: unset;

    @include mq('large') {
      margin-top: 140px;
    }

    @include mq('x-large') {
      max-width: 1100px; // as per figma
      margin-left: auto;
      margin-right: auto;
    }

    .close {
      max-width: var(
        --emu-component-container-max-width
      ); // to handle large screen width
      margin-left: auto;
      margin-right: auto;
      width: 100%; // to match figma
      left: 0;
      opacity: 0.5;
      font-size: 0px;
      line-height: 0px;

      span {
        height: var(--emu-common-spacing-large); // as per figma
        width: var(--emu-common-spacing-large); // as per figma
        background-color: var(--emu-common-colors-white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 25px;
        right: var(--emu-common-spacing-medium);

        @include mq('large') {
          height: 48px; // as per figma
          width: 48px; // as per figma
        }

        @include mq('x-large') {
          top: var(--emu-common-spacing-brand-medium);
          right: 57px;
        }

        &::before {
          content: url(../../assets/images/close-modal.png);
          display: block;
        }
      }
    }
  }

  &.interactive-map-modal {
    .modal-content {
      background-color: var(--emu-common-colors-transparent);
      max-width: 360px; // to match figma
      margin: auto;
      padding-top: 20px;
      padding-bottom: 20px;

      @include mq('large') {
        max-width: 427px; // to match figma
      }

      > .container:has(.brand-card),
      > .container.js-has-brand-card {
        display: flex;
        justify-content: center;
      }

      .close {
        position: relative;
        opacity: 1;

        span {
          background-color: var(--emu-common-colors-secondary-500);
          height: 36px;
          width: 36px;
          top: 18px;
          right: var(--emu-common-spacing-brand-xs);

          @include mq('large') {
            top: 20px;
          }
        }
      }
    }

    .brand-card {
      width: 100%; // as per figma

      .text {
        &:has(.brand-card__disc),
        &.has-brand-card__disc {
          & + .text > .brand-card__disc {
            margin-top: 21px;
          }
        }
      }

      &__content {
        min-height: 357px; // as per figma
        padding-top: 18px;
        padding-left: var(--emu-common-spacing-brand-xs);
        padding-right: var(--emu-common-spacing-brand-xs);
        padding-bottom: 13px;
        border: none;

        @include mq('large') {
          min-height: 360px; // as per figma
        }
      }

      &__title {
        margin-bottom: var(--emu-common-spacing-xs);

        @include mq('large') {
          margin-bottom: 10px;
        }
      }

      &__txt {
        margin-bottom: 9px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }

        p {
          @include mq('large') {
            font-size: var(--emu-common-font-sizes-body-medium);
          }
        }
      }
    }
  }
}

.js-modal-opened {
  overflow: hidden;

  @include aem-editor-view {
    overflow: initial;
  }
}
