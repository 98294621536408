html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

html,
body {
  font-feature-settings: 'liga' off, 'clig' off;
}

body {
  overflow-x: hidden !important; // making sure to override any styles added by AAAEM
}

main {
  @include max-container;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
button {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  color: inherit;
}

p + p {
  margin-top: var(--emu-common-spacing-large);
}

li + li {
  margin-top: var(--emu-common-spacing-small);
}

body {
  -webkit-overflow-scrolling: touch;
  font-size: var(--emu-common-font-sizes-body-regular);
  line-height: var(--emu-common-line-heights-narrow-jumbo);
  font-family: var(--emu-semantic-font-families-body);

  @include mq('large') {
    font-size: var(--emu-common-font-sizes-body-medium);
    line-height: var(--emu-common-line-heights-wide-xxxl);
  }
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

body {
  color: var(--emu-common-colors-black);
}
