.teaser {
  .emu-teaser {
    &.emu-teaser-v3.inline-text-overlay {
      &:not(.u-teaser--overlapped-on-mobile) {
        .teaser-container {
          @include mq('x-large', 'max-width') {
            display: flex;
            flex-direction: column-reverse;
          }

          .content-container {
            @include mq('x-large', 'max-width') {
              position: static;
            }
          }
        }
      }

      &.u-teaser--white-text-on-desktop {
        .content-container {
          @include mq('x-large') {
            color: var(--emu-semantic-colors-text-dark);
          }
        }
      }

      &.u-teaser--black-text-on-desktop {
        .content-container {
          @include mq('x-large') {
            color: var(--emu-semantic-colors-text-light);
          }
        }
      }

      &.u-teaser--content-bottom {
        .content {
          flex-grow: 1;

          .title-lockup {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }

          .emu-teaser__description {
            margin-top: auto;
          }
        }
      }

      &.u-teaser--overlapped-on-mobile {
        &.emu-teaser-v3 {
          background: linear-gradient(
            270deg,
            #ffdebd 0.03%,
            #fee1c5 25.64%,
            #fed8c4 63%,
            #fed4c4 80.49%,
            #fecabf 99.81%
          ); // as per the design

          .teaser-container {
            .emu-teaser__image img {
              @include mq('x-large', 'max-width') {
                max-height: 900px; // height does not exceed 900px
              }
            }

            .content-container {
              @include mq('x-large', 'max-width') {
                padding-top: 38px;
                padding-bottom: 21px;
              }

              // when there is no disclaimer, change the padding bottom
              &:not(:has(.emu-teaser__disclaimer)) {
                @include mq('x-large') {
                  padding-bottom: 45px;
                }
              }
            }
          }
        }
      }

      &.u-teaser--with-play-cta {
        .teaser-container {
          .emu-teaser {
            &__image {
              img {
                height: 800px; // TEMP CODE until the asset is received in Figma
              }
            }
          }

          // increasing specificity
          .content-container {
            .title-lockup {
              @include mq('large') {
                max-width: 800px;
              }
            }

            .emu-teaser {
              &__title {
                @include mq('large') {
                  letter-spacing: -1px;
                }
              }

              &__action-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: var(--emu-common-spacing-none);

                .emu-button-v2 {
                  width: 156px; // as per the design
                  height: 156px; // as per the design
                  border-radius: 50%;
                  justify-content: center;
                  align-items: center;

                  @include mq('large') {
                    padding-top: 14px;
                  }

                  span {
                    font-size: var(--emu-common-font-sizes-body-large);
                    font-weight: var(--emu-common-font-weight-600);
                    line-height: var(--emu-common-line-heights-narrow-xxxl);
                  }

                  &,
                  &:hover,
                  &:focus,
                  &:active {
                    background-color: var(
                      --emu-component-actions-button-color-background-primary-filled-default-light
                    );
                    color: var(
                      --emu-component-actions-button-text-color-primary-filled-default-light
                    );
                    border-color: var(
                      --emu-component-actions-button-color-background-primary-filled-default-light
                    );
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }

      .teaser-container {
        .emu-teaser__image {
          img {
            max-height: 390px; // as per figma
            object-fit: cover;
            object-position: center;

            @include mq('medium') {
              max-height: 460px; // as per figma
            }

            @include mq('x-large') {
              max-height: unset;
              min-height: 900px; // as per figma
            }
          }
        }

        .content-container {
          @include mq('x-large') {
            padding-top: 44px;
            padding-right: var(--emu-common-spacing-brand-medium);
            padding-bottom: var(--emu-common-spacing-brand-medium);
            padding-left: var(--emu-common-spacing-brand-medium);
          }

          &.text-theme-light {
            .u-cta--on-dark {
              &:focus {
                background-color: var(--emu-common-colors-black);
              }
            }
          }

          .emu-teaser__action-container,
          .title-lockup {
            max-width: 727px; // as per figma
          }

          .emu-teaser__disclaimer {
            position: static;
            margin-top: 50px;

            @include mq('x-large') {
              margin-top: auto;
            }

            p {
              font-size: var(--emu-common-font-sizes-captions-large);
              line-height: var(--emu-common-line-heights-narrow-jumbo);

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-body-small);
              }
            }
          }

          .emu-teaser__pretitle {
            font-family: var(--emu-common-font-families-sans);
            font-size: var(--emu-common-font-sizes-captions-large);
            line-height: var(--emu-common-line-heights-narrow-xxxl);
            font-weight: var(--emu-common-font-weight-regular);
            letter-spacing: 0.84px;
            margin-bottom: 15px;

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-body-small);
              letter-spacing: 0.98px;
            }

            @include mq('x-large') {
              margin-bottom: 20px;
            }
          }

          .emu-teaser__title {
            margin-bottom: 14px;

            @include mq('x-large') {
              margin-bottom: 28px;
            }

            b {
              font-weight: inherit;

              @include mq('x-large') {
                display: inline-block;
              }
            }

            sup {
              font-size: 60%;
              top: -1px;

              @include mq('large') {
                top: 3px;
              }
            }
          }

          .emu-teaser__description {
            @include mq('x-large') {
              max-width: 424px; // as per figma
            }

            p {
              display: block !important; // needed as AEM is adding inline styles making the p tag display inline

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-body-large);
                line-height: var(--emu-common-line-heights-narrow-xxxl);
              }
            }

            p + p {
              margin-top: var(--emu-common-spacing-brand-xs);

              @include mq('x-large') {
                margin-top: 29px;
              }
            }
          }

          .emu-teaser__action-container {
            margin-top: var(--emu-common-spacing-brand-xs);

            @include mq('x-large') {
              margin-top: var(--emu-common-spacing-brand-small);
            }
          }
        }
      }

      &.u-teaser--mobile-img-fit-inside:not(.js-is-desktop-img) {
        .emu-teaser__image img {
          @media (min-width: 500px) and (max-width: 1023px) {
            object-fit: contain;
          }
        }
      }
    }
  }
}
