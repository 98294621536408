// Resources Teaser. Teaser with
.teaser {
  .emu-teaser-v3.emu-teaser-v3.emu-teaser-v3.inline-text-overlay.resources-teaser {
    .teaser-container {
      .content-container {
        padding-top: var(--emu-common-spacing-brand-small);
        padding-bottom: 11px;

        @include mq('x-large') {
          padding-top: 60px;
        }
      }

      .emu-teaser {
        &__title {
          font: var(--emu-component-ingredients-title-h1-typography-narrow);
          margin-bottom: 17px;

          @include mq('large') {
            font: var(--emu-component-ingredients-title-h2-typography-wide);
            margin-bottom: 22px;
          }
        }

        &__description {
          p {
            font-size: var(--emu-common-font-sizes-body-regular);
            line-height: var(--emu-common-line-heights-narrow-jumbo);
            font-family: var(--emu-common-font-families-sans);
            letter-spacing: normal;
          }
        }

        &__subtext {
          padding-top: var(--emu-common-spacing-none);
          margin-top: 22px;

          @include mq('large') {
            margin-top: 23px;
          }

          p {
            font-size: var(--emu-common-font-sizes-captions-large);
          }
        }

        &__action-container {
          @include mq('x-large') {
            margin-top: var(--emu-common-spacing-brand-xs);
          }
        }

        &__disclaimer {
          margin-top: 39px;
          border-top: var(--emu-common-border-width-none);

          @include mq('x-large') {
            position: absolute;
            padding-left: var(--emu-common-spacing-brand-medium);
            padding-bottom: 52px;
          }
        }

        &__image {
          img {
            max-height: 425px; //as per figma

            @include mq('x-large') {
              min-height: 600px; //as per figma
            }
          }
        }
      }
    }
  }
}
