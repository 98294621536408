.promo {
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  padding-left: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-top: 54px;
    padding-right: var(--emu-common-spacing-brand-medium);
    padding-bottom: var(--emu-common-spacing-brand-medium);
    padding-left: var(--emu-common-spacing-brand-medium);
  }

  &__img-container {
    margin-bottom: 43px;

    @include mq('large') {
      margin-bottom: 38px;
    }

    img {
      min-height: 127px; // As per figma
      object-fit: cover;

      @include mq('large') {
        min-height: 146px; // As per figma
      }
    }
  }

  &__content {
    padding-bottom: 99px;
    padding-left: 24px;
    padding-right: 24px;

    @include mq('large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: 80px;
    }
  }

  &__title {
    .cmp-title__text {
      @include mq('large', 'max-width') {
        font-size: var(--emu-common-font-sizes-narrow-large);
        letter-spacing: -0.64px;
        margin-bottom: 41px;
      }
    }

    @include mq('large') {
      margin-bottom: 23px;
    }
  }

  &__txt {
    max-width: 675px; // As per figma
    margin: auto;
    margin-bottom: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-large);
    }
  }
}
