footer.experiencefragment {
  .footer {
    &__wrapper {
      padding-top: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: 33px;
      padding-left: var(--emu-common-spacing-medium);

      @include mq('medium') {
        padding: var(--emu-common-spacing-brand-medium);
      }
    }

    &__logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @include mq('medium') {
        flex-direction: row;
      }
    }

    &__social-media {
      margin-top: var(--emu-common-spacing-large);
      display: flex;
      align-items: center;

      @include mq('medium') {
        margin-top: var(--emu-common-spacing-none);
      }

      > .image {
        &:not(:first-child) {
          margin-left: var(--emu-common-spacing-brand-xs);

          @include mq('medium') {
            margin-left: 29px;
          }
        }
      }
    }

    &__links {
      padding-top: var(--emu-common-spacing-brand-xs);
      padding-bottom: 22px;

      @include mq('medium') {
        padding-top: 27px;
        padding-bottom: 29px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include mq('medium') {
          justify-content: left;
        }

        li {
          position: relative;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-xxs);

          &:not(:last-child) {
            margin-right: 13px;
          }

          &:not(:first-child) {
            margin-left: var(--emu-common-spacing-xs);
          }

          img {
            margin-left: var(--emu-common-spacing-xxs);
            top: 3px;
            position: relative;
            width: 30px;
          }

          &:last-child {
            a {
              &:after {
                display: none;
              }
            }
          }

          .emphasis a {
            &:after {
              margin-left: 40px;
            }
          }
        }

        a {
          text-decoration: none;
          font-size: var(--emu-common-font-sizes-body-small);
          line-height: var(--emu-common-line-heights-custom-xxxl);
          letter-spacing: -0.2px;

          &:after {
            content: '';
            background: var(--emu-common-colors-brand-grey-500);
            position: absolute;
            top: 7px;
            right: -11px;
            height: 13px;
            width: var(--emu-common-sizing-xxs);
            pointer-events: none;

            @include mq('medium') {
              right: -9px;
            }
          }
        }
      }
    }

    &__copyrights {
      &-text {
        font-size: var(--emu-common-font-sizes-body-small);
        color: var(--emu-common-colors-brand-grey-400);
        line-height: var(--emu-common-line-heights-custom-xxxl);
        letter-spacing: -0.2px;
        text-align: center;

        @include mq('medium') {
          text-align: left;
        }
      }
    }
  }
}
