.faq {
  padding-bottom: 100px;

  @include mq('large') {
    padding-bottom: var(--emu-common-spacing-none);
  }

  &__inner {
    @include mq('large') {
      display: flex;

      > .container {
        flex: 1;
      }
    }
  }

  &__section {
    &--left {
      padding-top: var(--emu-common-spacing-brand-xs);
      padding-bottom: 14px;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      overflow: hidden;

      @include mq('large') {
        padding-top: 47px;
        padding-bottom: var(--emu-common-spacing-brand-medium);
        padding-left: var(--emu-common-spacing-brand-medium);
        padding-right: var(--emu-common-spacing-brand-medium);
      }
    }

    &--right {
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding: var(--emu-common-spacing-brand-medium);
      }

      > .button {
        display: flex;
        justify-content: end;
      }
    }
  }

  &__description {
    margin-top: var(--emu-common-spacing-brand-xs);
    margin-bottom: 26px;

    @include mq('large') {
      margin-top: 18px;
      max-width: 529px; //as per design
    }

    sup {
      font-size: 100%;
      top: 6px;
    }
  }

  &__filter-cta {
    &-container {
      margin-top: var(--emu-common-spacing-medium);
      display: flex;
      margin-left: -16px;
      margin-right: -16px;
      scrollbar-width: none;
      overflow-x: auto;

      @include mq('large') {
        margin-top: 27px;
        flex-wrap: wrap;
        overflow: hidden;
      }

      // increasing specificity
      &.cmp-container {
        @include mq('large') {
          max-width: 500px; //as per design
        }
      }

      > .button {
        margin-bottom: var(--emu-common-spacing-medium);
        margin-left: var(--emu-common-spacing-medium);

        @include mq('large', 'max-width') {
          order: 1;
        }

        &.faq__filter-page-selected-cta {
          @include mq('large', 'max-width') {
            order: 0;
          }
        }

        &:last-child {
          margin-right: var(--emu-common-spacing-medium);
        }
      }
    }
  }

  &__toggler {
    &.emu-button.emu-button-v2.emu-button-v2.emu-button-v2 {
      padding: var(--emu-common-spacing-none);
      background-color: var(--emu-common-colors-transparent);
      border: none;
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-bottom: 15px;
      }

      &,
      &:focus,
      &:hover,
      &:active {
        background-color: var(--emu-common-colors-transparent);
        border: none;
        outline-width: 0px;
      }

      &.js-toggle-on {
        img {
          transform: rotate(180deg);
        }
      }

      &.faq__toggler--active {
        .cmp-button__icon-text {
          display: block;
        }

        .cmp-button__text {
          display: none;
        }

        img {
          transform: rotate(180deg);
        }
      }

      img {
        transition: all 0.2s linear;
      }

      span > span {
        line-height: var(--emu-common-line-heights-narrow-jumbo);
        margin-right: var(--emu-common-spacing-xs);
      }

      .cmp-button__icon-text {
        display: none;
      }
    }
  }

  &__block {
    display: none;

    &--active {
      display: block;
    }
  }

  &__accordion-text {
    p + p {
      margin-top: 21px;

      @include mq('large') {
        margin-top: 23px;
      }
    }
  }
}
