h1,
.u-typography--h1 {
  letter-spacing: -0.8px;

  @include mq('large') {
    letter-spacing: -1.44px;
  }
}

h2,
.u-typography--h2 {
  letter-spacing: -0.96px;

  @include mq('large') {
    letter-spacing: -1.44px;
  }
}

h3,
.u-typography--h3 {
  letter-spacing: -0.8px;

  @include mq('large') {
    letter-spacing: -0.96px;
  }
}

h4,
.u-typography--h4 {
  letter-spacing: -0.64px;
}

h5,
.u-typography--h5 {
  letter-spacing: -0.48px;

  @include mq('large') {
    letter-spacing: -0.64px;
  }
}

.u-typography--intro-heading {
  letter-spacing: -0.8px;

  @include mq('large') {
    letter-spacing: -1.44px;
  }
}
