.feature {
  padding-top: var(--emu-common-spacing-brand-small);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-top: 102px;
    padding-bottom: 135px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    img {
      @include aem-editor-view {
        max-width: 400px;
      }
    }
  }

  &__title {
    margin-bottom: 30px;

    @include mq('large') {
      margin-bottom: 46px;
    }

    .cmp-title__text {
      font-size: var(--emu-common-font-sizes-display-small);
      line-height: var(--emu-common-line-heights-narrow-small);
      letter-spacing: -1.28px;

      @include mq('large') {
        font-size: 112px;
        letter-spacing: -2.24px;
      }
    }

    sup {
      top: 1px;
    }
  }

  &__txt {
    margin-bottom: var(--emu-common-spacing-brand-small);

    @include mq('large') {
      margin-bottom: 26px;
      max-width: 650px; // as per figma
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__disc {
    @include mq('large') {
      max-width: 508px; // as per figma
      margin-left: auto;
      margin-right: auto;
    }
  }
}
