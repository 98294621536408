.tabbed-teaser {
  &__tabs {
    position: relative;

    .aaaem-tabs {
      &__tablist {
        border: var(--emu-common-border-width-thick) solid
          var(--emu-common-colors-white);
        border-radius: var(--emu-common-border-radius-small);
        background-color: var(--emu-common-colors-white);
        position: absolute;
        top: 40px;
        z-index: var(--emu-common-other-z-index-layer);
        margin-left: var(--emu-common-spacing-medium);
        margin-right: var(--emu-common-spacing-medium);
        padding: var(--emu-common-spacing-none);

        @include mq('x-large') {
          top: 56px;
          margin-left: var(--emu-common-spacing-brand-medium);
          margin-right: var(--emu-common-spacing-brand-medium);
        }

        li + li {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      &__tab {
        // media query needed to make sure that the tab pills aligned well in smaller mobile devices
        @include mq('390px') {
          min-width: 175px; // to match figma
        }

        @include mq('x-large') {
          max-width: 240px; // to match figma
        }

        a {
          border-radius: var(--emu-common-border-radius-small);
          text-align: center;
          white-space: normal;
          border: none;
          font-family: var(--emu-common-font-families-sans);

          @include mq('x-large') {
            padding-top: var(--emu-common-spacing-xxs);
            padding-right: 28px;
            padding-bottom: 3px;
            padding-left: var(--emu-common-spacing-brand-xs);
          }

          // increasing specificity
          &[data-ref='tab'] {
            opacity: 1;
          }

          span {
            font-size: var(--emu-common-font-sizes-captions-large);
            line-height: var(--emu-common-line-heights-narrow-jumbo);
            display: block;

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-body-small);
            }
          }
        }
      }
    }
  }

  &__tab-content {
    position: relative;
  }

  &__main.emu-teaser.emu-teaser-v3.emu-teaser-v3.cmp-teaser {
    .teaser-container {
      .emu-teaser__image img {
        max-height: 540px;

        @include mq('x-large') {
          min-height: 0;
          max-height: unset;
          min-height: 856px; // original is 960, but images are not looking good between 1200 and 1300.
        }
      }

      .content-container {
        padding-top: 119px;

        @include mq('x-large') {
          padding-top: 167px;
        }

        .title-lockup {
          max-width: none;

          @include mq('x-large') {
            max-width: 487px;
          }
        }

        .emu-teaser {
          &__action-container {
            margin-bottom: 7px;
          }

          &__title {
            margin-bottom: 22px;

            @include mq('large') {
              font-size: var(--emu-common-font-sizes-display-small);
              line-height: 1;
              letter-spacing: -1.28px;
            }

            @include mq('x-large') {
              margin-bottom: 29px;
            }
          }
        }
      }
    }

    &.tabbed-teaser__main--lines {
      .content-container {
        .emu-teaser__action-container {
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }

    // intentionally placed after all the styles to override
    &.tabbed-teaser__main--bands {
      .teaser-container {
        .title-lockup {
          @include mq('x-large') {
            max-width: 553px; // as per figma
          }
        }

        .content-container {
          .emu-teaser {
            &__title {
              margin-bottom: 20px;

              @include mq('large') {
                line-height: var(--emu-common-line-heights-narrow-small);
              }

              @include mq('x-large') {
                margin-bottom: 31px;
              }
            }

            &__description {
              @include mq('x-large') {
                max-width: 460px;
              }
            }

            &__action-container {
              margin-bottom: 13px;
            }
          }
        }
      }
    }
  }

  &__disclaimer {
    position: absolute;
    left: 18px;
    bottom: var(--emu-common-spacing-medium);

    @include mq('x-large') {
      max-width: 402px; // as per figma
      left: var(--emu-common-spacing-brand-medium);
      bottom: var(--emu-common-spacing-brand-medium);
      padding: var(--emu-common-spacing-none);
    }

    @include aem-editor-view {
      position: static !important;
    }

    &--right-align-in-mobile {
      @include mq('large', 'max-width') {
        text-align: right;
      }
    }

    &--on-img-in-mobile {
      @include mq('large', 'max-width') {
        position: absolute;
        right: var(--emu-common-spacing-medium);
        bottom: 18px;
      }
    }
  }

  &__bottom-txt {
    padding-top: 9px;
    padding-bottom: 10px;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-top: 10px;
      padding-left: var(--emu-common-spacing-brand-medium);
      padding-right: var(--emu-common-spacing-brand-medium);
    }

    p + p {
      margin-top: 20px;
    }
  }
}
