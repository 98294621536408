.interactive-map {
  display: flex;
  flex-direction: column-reverse;

  @include mq('medium') {
    flex-direction: row;
  }

  > .container {
    @include mq('medium') {
      flex: 1;
    }
  }

  &__asset {
    position: relative;

    @include mq('medium') {
      border-right: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
    }
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;

      @include mq('medium') {
        max-height: 900px; // to match figma
      }
    }

    .emu-mapping-marker {
      span {
        pointer-events: none;

        &::after,
        &::before {
          display: none;
        }
      }
    }
  }

  &__content {
    padding-top: 39px;
    padding-bottom: 30px;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);

    @include mq('medium') {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    @include mq('large') {
      padding-top: 59px;
      padding-left: 53px;
      padding-right: 59px;
      padding-bottom: var(--emu-common-spacing-brand-medium);
    }
  }

  &__title {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-bottom: 18px;
    }

    .cmp-title__text {
      @include mq('large') {
        font-size: 56px; // no variable available for this font size
        line-height: var(--emu-common-line-heights-custom-xs);
        letter-spacing: -1px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 13px;

    @include mq('large') {
      margin-bottom: 23px;
    }
  }

  &__cta {
    margin-bottom: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }
}
