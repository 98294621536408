.intro-box {
  padding-top: var(--emu-common-spacing-brand-xs);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-brand-medium);
  padding-left: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-top: 47px;
    padding-right: var(--emu-common-spacing-brand-medium);
    padding-bottom: var(--emu-common-spacing-brand-large);
    padding-left: var(--emu-common-spacing-brand-medium);
  }

  &__pretitle {
    margin-bottom: var(--emu-common-spacing-small);

    @include mq('large') {
      margin-bottom: 25px;
    }

    p {
      font-size: var(--emu-common-font-sizes-captions-large);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      letter-spacing: 0.84px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-body-small);
        letter-spacing: 0.98px;
      }
    }
  }

  &__title {
    margin-bottom: 14px;

    @include mq('large') {
      margin-bottom: 26px;
    }
  }

  &__txt {
    @include mq('large') {
      max-width: 652px; // As per design
    }
  }

  &__cta {
    &-container {
      margin-top: 25px; // intentionally added margin top, as cta might be optional
    }
  }

  &__disc {
    margin-top: var(--emu-common-spacing-brand-xs);

    @include mq('large') {
      margin-top: 39px;
    }
  }

  // Variation of intro box being used as hero
  &--as-hero {
    &.intro-box {
      padding-top: var(--emu-common-spacing-large);

      @include mq('large') {
        padding-top: 54px;
        padding-bottom: 207px;
      }

      .intro-box {
        &__title {
          @include mq('large') {
            margin-bottom: 27px;
          }
        }

        &__pretitle {
          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-brand-xs);
          }

          p {
            letter-spacing: 0.98px;
            font-size: var(--emu-common-font-sizes-body-small);
            line-height: var(--emu-common-line-heights-narrow-xxxl);
          }
        }
      }
    }
  }
}
