sup {
  vertical-align: super;
  font-size: 65%;
  line-height: 0;
  position: relative;
  top: -0.45em;
}

b {
  font-weight: var(--emu-common-font-weight-600);
}
