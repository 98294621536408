.ba__modal {
  &[data-component='modal'] {
    .modal-wrapper.is-open {
      align-items: start;
    }

    .modal-content {
      margin: var(--emu-common-spacing-none);

      @include mq('large') {
        max-width: none;

        // to view carousel in full screen
        .aaaem-container.cmp-container {
          max-width: none;
        }

        .aaaem-container {
          max-width: none;
        }
      }

      .close {
        z-index: var(--emu-common-other-z-index-overlay);
      }
    }
  }

  &-main {
    position: relative;
  }

  &-title-container {
    width: 100%;
    z-index: var(--emu-common-other-z-index-layer);
    padding-top: var(--emu-common-spacing-brand-xs);
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);

    @include mq('medium', 'max-width') {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        #000 102.44%
      ); // as per figma
      min-height: 148px; // as per figma
      position: absolute;
    }

    @include mq('medium') {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 66px;
    }
  }

  &-title {
    margin-bottom: var(--emu-common-spacing-small);

    @include mq('medium') {
      margin-bottom: 9px;
    }

    .cmp-title__text {
      @include mq('medium', 'max-width') {
        font-family: var(--emu-common-font-families-sans);
        font-size: var(--emu-common-font-sizes-captions-large);
        line-height: var(--emu-common-line-heights-narrow-jumbo);
        letter-spacing: normal;
        font-weight: var(--emu-common-font-weight-600);
      }

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-headline-medium);
        line-height: var(--emu-common-line-heights-narrow-xl);
        letter-spacing: -0.48px;
      }
    }
  }

  &-txt {
    width: 224px; // as per figma

    @include mq('medium') {
      width: auto;
    }

    p {
      font-size: var(--emu-common-font-sizes-captions-medium);

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-body-small);
      }
    }
  }

  &-carousel {
    &[data-component='carousel-v2'].emu-carousel.emu-carousel {
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      background-color: var(--emu-common-colors-transparent);
      opacity: 0;
      transform: translateX(50%);
      transition: all 0.8s ease-in-out;
      overflow: hidden;

      @include mq('medium') {
        margin-left: 6px;
        padding-bottom: 140px;
      }

      @include aem-editor-view {
        opacity: 1;
        transform: none;
      }

      .tns-ovh {
        overflow: visible;
        max-width: 390px;
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 335px;
        }
      }

      &.u-carousel--visible {
        opacity: 1;
        transform: translateX(0);
      }

      &.u-carousel--disabled {
        .emu-carousel__content {
          .emu-carousel__item {
            opacity: 1;
          }
        }
      }

      .emu-carousel__content {
        .emu-carousel__item {
          background-color: var(--emu-common-colors-transparent);
          opacity: 0.4;
          transition: all var(--emu-common-other-time-transition-base) ease-out;

          &.js-slide-active {
            opacity: 1;
            transition: all var(--emu-common-other-time-transition-base) ease-in;
          }
        }
      }
    }
  }

  &-section {
    position: relative;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-img {
    img {
      max-width: 100dvw;
      width: 390px; // as per figma

      @include mq('medium') {
        width: 335px; // as per figma
      }
    }
  }

  &-label {
    position: absolute;
    bottom: 0;
    padding-top: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-small);
    padding-left: var(--emu-common-spacing-medium);

    @include mq('medium') {
      top: 0;
      right: 0;
      bottom: unset;
    }

    p {
      font-size: var(--emu-common-font-sizes-captions-medium);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      letter-spacing: 0.7px;

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-body-small);
        letter-spacing: 0.98px;
      }
    }
  }
}
