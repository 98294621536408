.ba-item {
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-left: var(--emu-common-spacing-brand-medium);
    padding-right: var(--emu-common-spacing-brand-medium);
    padding-bottom: var(--emu-common-spacing-brand-medium);
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include mq('large') {
      flex-direction: row;
    }

    > .container {
      &:first-child {
        @include mq('large') {
          width: 480px; // as per the design
        }
      }

      &:last-child {
        @include mq('large') {
          flex: 1;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-left: var(--emu-common-spacing-brand-xs);
    padding-right: var(--emu-common-spacing-brand-xs);
    padding-bottom: var(--emu-common-spacing-large);

    @include mq('large') {
      height: 100%;
      max-width: 325px; // As per figma
    }

    @include mq('x-large') {
      padding-left: var(--emu-common-spacing-brand-medium);
      padding-right: var(--emu-common-spacing-brand-medium);
      padding-bottom: var(--emu-common-spacing-brand-medium);
      padding-top: var(--emu-common-spacing-brand-medium);
    }
  }

  &__pretitle {
    margin-bottom: 20px;

    p {
      font-size: var(--emu-common-font-sizes-captions-large);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      letter-spacing: 0.84px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-body-regular);
        line-height: var(--emu-common-line-heights-narrow-xl);
        letter-spacing: 1.12px;
      }
    }
  }

  &__title {
    margin-bottom: var(--emu-common-spacing-medium);
    padding-top: 18px;

    @include mq('large') {
      padding-top: 15px;
      margin-bottom: 14px;
    }

    .cmp-title__text {
      @include mq('large', 'max-width') {
        font-size: var(--emu-common-font-sizes-narrow-large);
        line-height: var(--emu-common-line-heights-narrow-large);
        letter-spacing: -0.64px;
      }
    }
  }

  &__txt {
    margin-bottom: var(--emu-common-spacing-brand-xs);
  }

  &__cta {
    margin-bottom: 55px;
  }

  &__disc {
    @include mq('large') {
      padding-right: var(--emu-common-spacing-brand-xs);
    }
  }

  &__assets {
    display: flex;

    > .container {
      flex: 1;
    }

    img {
      width: 100%;
      height: 358px; // to match the design
      object-fit: cover;

      @include mq('large') {
        height: 600px; // to match the design
      }
    }
  }

  &__img-container {
    position: relative;
  }

  &__img-label {
    position: absolute;
    top: 0;
    right: 0;

    p {
      font-size: var(--emu-common-font-sizes-captions-medium);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      letter-spacing: 0.7px;
      padding-top: 9px;
      padding-bottom: var(--emu-common-spacing-small);
      padding-left: 12px;
      padding-right: 12px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-body-small);
        letter-spacing: 0.98px;
        padding-top: 6px;
        padding-right: var(--emu-common-spacing-medium);
        padding-left: var(--emu-common-spacing-medium);
      }
    }
  }

  &__main-disclaimer {
    padding-top: 26px;

    @include mq('large') {
      padding-top: 72px;
    }

    p + p {
      margin-top: 18px;
    }
  }
}
