.ba__modal {
  &-carousel {
    &[data-component='carousel-v2'].emu-carousel.emu-carousel {
      .tns-outer {
        .tns-nav {
          bottom: var(--emu-common-spacing-brand-medium);

          @include mq('large') {
            bottom: -85px;
          }

          button.tns-nav-active {
            @include mq('large', 'max-width') {
              background-color: var(
                --emu-component-containers-carousel-indicators-dots-color-background-active-light
              );
              border-color: var(
                --emu-component-containers-carousel-indicators-dots-color-border-active-light
              );
            }
          }
        }
      }
    }
  }

  &-img {
    img {
      @include mq('large', 'max-width') {
        height: 339.5px; // as per figma
      }
    }
  }
}
