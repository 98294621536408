.references {
  padding-top: var(--emu-common-spacing-brand-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-brand-medium);
  padding-left: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-left: var(--emu-common-spacing-brand-medium);
    padding-right: var(--emu-common-spacing-brand-medium);
  }

  &__txt {
    p,
    li {
      font-size: var(--emu-common-font-sizes-body-small);
      line-height: var(--emu-common-line-heights-narrow-jumbo);
    }

    p + p,
    li + li {
      margin-top: 21px;

      @include mq('large') {
        margin-top: 20px;
      }
    }

    a {
      word-break: break-all;
    }
  }
}
