#tl3 {
  .lo3 {
    &__teaser {
      .teaser {
        &-container .content-container {
          .emu-teaser__title sup {
            @include mq('large') {
              top: -4px;
            }
          }

          .emu-teaser__description {
            sup {
              top: 0.6px;

              @include mq('large') {
                font-size: 30%;
                top: -5px;
              }
            }
          }
        }
      }
    }

    &__feature {
      padding-bottom: var(--emu-common-spacing-brand-large);

      @include mq('large') {
        padding-bottom: 80px;
      }

      .feature {
        &__inner {
          @include mq('large') {
            max-width: 878px; // as per figma
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 80px; // as per figma
          }

          @include mq('x-large') {
            max-width: 1100px; // as per figma
          }
        }

        &__img img {
          @include mq('large') {
            margin-left: 28px;
          }
        }

        &__title {
          margin-top: -7px; // needed to match the design

          @include mq('large') {
            margin-top: -16px; // needed to match the design
          }
        }

        &__txt {
          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-brand-xs);
          }

          sup {
            top: 0.2px;
          }
        }
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__title {
          margin-bottom: 14px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-large);
          }
        }

        &__text {
          margin-bottom: var(--emu-common-spacing-none);
          letter-spacing: -0.01px;

          sup {
            top: 1px;
            font-size: 55%;
          }
        }

        &__disc {
          margin-top: 51px;

          &--narrow-more-space {
            margin-bottom: 50px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &--64-units {
        @include mq('large') {
          margin-top: 3px;
        }

        .img-txt-grid {
          &__section {
            &--content {
              @include mq('large') {
                padding-bottom: 52px;
                padding-top: 52px;
              }
            }
          }
        }
      }

      &--3-times {
        @include mq('large') {
          margin-top: 3px;
        }

        .img-txt-grid {
          &__section {
            &--content {
              @include mq('large') {
                padding-bottom: 48px;
                padding-top: 57px;
              }
            }
          }

          &__disc {
            @include mq('large', 'max-width') {
              margin-top: 100px;
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    &__real-results-intro-box {
      .intro-box {
        &__title {
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('large') {
            margin-bottom: 29px;
          }
        }

        &__cta-container {
          margin-top: 22px;

          @include mq('large') {
            margin-top: 25px;
          }
        }
      }
    }

    &__ba-item {
      .ba-item {
        &__content {
          @include mq('large', 'max-width') {
            padding-bottom: var(--emu-common-spacing-large);
          }
        }

        &__cta {
          margin-bottom: 55px;
        }
      }
    }

    &__how-to-heading {
      padding-top: 23px;

      @include mq('large') {
        padding-top: 46px;
        padding-bottom: 29px;
      }

      .intro-box {
        &__title {
          @include mq('large') {
            max-width: 60%; // needed to match design
          }
        }
      }
    }

    &__brand-cards {
      padding-right: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-small);
      padding-top: 25px;

      @include mq('large') {
        display: flex;
        padding-top: 26px;
        padding-right: var(--emu-common-spacing-brand-medium);
        padding-left: var(--emu-common-spacing-brand-medium);
        padding-bottom: 31px;
        justify-content: center;
      }

      > .container {
        @include mq('large') {
          &:not(:last-child) {
            padding-right: var(--emu-common-spacing-brand-xs);
          }
        }
      }

      .brand-card {
        width: 100%;
        padding-bottom: 25px;

        @include mq('large') {
          max-width: 428px; // as per figma
        }

        &__content {
          padding-left: var(--emu-common-spacing-brand-xs);
          padding-bottom: 30px;

          @include mq('large') {
            padding-left: var(--emu-common-spacing-large);
            padding-bottom: var(--emu-common-spacing-large);
          }
        }

        &__title {
          padding-bottom: 9px;
          margin-bottom: var(--emu-common-spacing-small);
        }

        &__asset img {
          max-height: 180px; // as per figma
          object-fit: cover;

          @include mq('large') {
            max-height: 233px; // as per figma
          }
        }
      }
    }

    &__tabbed-teaser {
      .tabbed-teaser {
        &__main .teaser-container .content-container {
          @include mq('large', 'max-width') {
            padding-top: 119px; // needed to match design
          }

          .emu-teaser {
            &__title {
              margin-bottom: 22px;

              @include mq('x-large') {
                margin-bottom: 30px;
              }
            }
          }
        }

        &__tabs .aaaem-tabs__tab {
          @include mq('large') {
            max-width: 228px; // as per figma

            a {
              padding-right: var(--emu-common-spacing-brand-xs);
            }
          }
        }
      }

      &__disclaimer--right-align-in-mobile {
        text-align: right;

        @include mq('large') {
          text-align: left;
        }
      }

      .cmp-teaser__description {
        max-width: 476px;

        @include mq('large', 'max-width') {
          max-width: 350px; // as per figma
        }
      }
    }
  }

  .pre-footer {
    z-index: var(--emu-common-other-z-index-layer);
    position: relative;

    .intro-box {
      padding-bottom: 51px;
      @include mq('large') {
        padding-bottom: 66px;
      }
    }
  }

  .aaaem-isi-container__isi-content > div {
    margin-top: -21px; // needed to match design

    @include mq('large') {
      margin-top: -23px; // needed to match design
    }
  }
}
