.error {
  padding-top: 31px;
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: 35px;

  @include mq('large') {
    padding-top: 55px;
    padding-left: var(--emu-common-spacing-brand-medium);
    padding-right: var(--emu-common-spacing-brand-medium);
    padding-bottom: 83px;
  }

  &--500 {
    padding-bottom: var(--emu-common-spacing-small);

    @include mq('large') {
      padding-bottom: 83px;
    }
  }

  &__pre-title {
    margin-bottom: 7px;

    @include mq('large') {
      margin-bottom: 25px;
    }

    p {
      font-size: var(--emu-common-font-sizes-captions-large);
      line-height: var(--emu-common-line-heights-narrow-xxxl);
      letter-spacing: 0.84px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-body-small);
        letter-spacing: 0.98px;
      }
    }
  }

  &__title {
    margin-bottom: 14px;

    @include mq('large') {
      margin-bottom: 28px;
    }

    .cmp-title__text.cmp-title__text {
      letter-spacing: -0.96px;

      @include mq('large') {
        letter-spacing: -1.44px;
      }
    }

    p + p {
      margin-top: 49px;

      @include mq('large') {
        margin-top: 67px;
      }
    }
  }

  &__txt {
    p {
      @include mq('large') {
        font-size: var(--emu-common-font-sizes-body-large);
        line-height: var(--emu-common-line-heights-narrow-xxxl);
      }
    }
  }

  &__cta {
    margin-top: var(--emu-common-spacing-brand-xs);
  }
}
