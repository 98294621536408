@font-face {
  font-family: 'Teodor';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/teodor/teodor-regular-web.woff2') format('woff2');
}

@font-face {
  font-family: 'Teodor';
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url('./assets/fonts/teodor/teodor-regular-italic-web.woff2')
    format('woff2');
}
